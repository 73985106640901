import produce from 'immer';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { Action } from 'redux-actions';

import { LoadableData } from '../../../../common/utils/LoadableData';
import { GlobalState } from '../../../../rootReducer';
import { InvoiceDataResponseDTO } from '../../../../services/types/ApiTypes';
import { setConfirmationFlowLoading, setInvalidTransactionRows, setIsConfirmationFlowEditing } from './InvoiceConfirmationsAction';

class State {
    invoiceDataLoadable = new LoadableData<InvoiceDataResponseDTO, string>();
    isConfirmationFlowLoading = false;
    isConfirmationFlowEditing = false;
    invalidTransacionRowsList: number[] = [];
}

export { State as InvoiceConfirmDataState };

export default new ReducerFactory(new State())
    .addReducer(
        setConfirmationFlowLoading,
        (state, action): State => {
            return {
                ...state,
                isConfirmationFlowLoading: action.payload,
            };
        },
    )
    .addReducer(
        setIsConfirmationFlowEditing,
        (state, action): State => {
            return {
                ...state,
                isConfirmationFlowEditing: action.payload,
            };
        },
    )
    .addReducer(
        setInvalidTransactionRows,
        produce((state, action: Action<number[]>) => {
            state.invalidTransacionRowsList = action.payload;
        }),
    )
    .toReducer();

export const selectInvoiceDataLoadable = (state: GlobalState) => state.invoiceConfirmData.invoiceDataLoadable;
export const selectIsConfirmationFlowLoading = (state: GlobalState) => state.invoiceConfirmData.isConfirmationFlowLoading;
export const selectIsConfirmationFlowEditing = (state: GlobalState) => state.invoiceConfirmData.isConfirmationFlowEditing;
export const selectInvalidTransactionRowsList = (state: GlobalState) => state.invoiceConfirmData.invalidTransacionRowsList;
