import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    BaseSearch,
    CompanyDTO,
    FileDTO,
    GroupMemberApproverDTO,
    GroupMemberApproversParamDTO,
    GroupMemberDTO,
    PagedListContainer,
    PagedListContainerOf,
    VatCodeDTO,
    SystemConfigurationDTO,
    GroupMemberMicroDTO,
    SerbianCompanyType,
    SerbiaCompanyData,
    GroupMemberCommonDTO,
    CompanyInvoiceCountDTO,
    CompanyDataDTO,
    InvoiceCustomFieldDTO,
    CompanyTimeZoneDTO,
    Restriction,
} from '../types/ApiTypes';
import { UploadProgress } from '../ApiClient';

export default class CompanyService {
    constructor(private axios: AxiosInstance) {}

    getCompanies = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<CompanyDTO>>> => {
        return this.axios.post('/company/GetCompanies', {
            ...searchParams,
        });
    };

    getCompaniesByName = async (name: string): Promise<AxiosResponse<CompanyDTO[]>> => {
        return this.axios.get('/company/GetByName', {
            params: {
                name,
            },
        });
    };

    getCompanyById = async (companyId: number): Promise<AxiosResponse<CompanyDTO>> => {
        return this.axios.get('/company/GetCompanyById', {
            params: {
                companyId,
            },
        });
    };

    getCurrentCompany = async (): Promise<AxiosResponse<CompanyDTO>> => {
        return this.axios.get('/company/GetCurrentCompany');
    };

    getCompanyData = async (): Promise<AxiosResponse<CompanyDataDTO>> => {
        return this.axios.get('/company/GetCompanyData');
    };

    getSerbiaCompanyData = async (regNumber: string, companyType: SerbianCompanyType): Promise<AxiosResponse<SerbiaCompanyData>> => {
        return this.axios.get('/company/GetSerbiaCompanyData', {
            params: {
                regNumber,
                companyType,
            },
        });
    };

    addCompany = async (company: CompanyDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post('/Company/AddCompany', company);
    };

    updateCompany = async (company: CompanyDTO): Promise<AxiosResponse<any>> => {
        return this.axios.post('/Company/UpdateCompany', company);
    };

    deleteCompany = async (companyId: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/Company/DeleteCompany`, companyId, {
            // axios sends the Payload as FormData otherwise
            headers: {
                'Content-Type': 'application/json',
            },
        });
    };

    uploadCSVFile = async (file: FileDTO, onUploadProgress?: UploadProgress): Promise<AxiosResponse<any>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post('/Company/UploadCSVFile', file, config);
    };

    getCompanyGroupMembers = async (companyGuid: string): Promise<AxiosResponse<GroupMemberDTO[]>> => {
        return this.axios.get('/groupMember/GetCompanyGroupMembers', {
            params: {
                boCompanyGuid: companyGuid,
            },
        });
    };

    getGroupMemberByUserGuid = async (userGuid: string): Promise<AxiosResponse<GroupMemberDTO>> => {
        return this.axios.get(`GroupMember/GetGroupMember?userGuid=${userGuid}`);
    };

    getCommonGroupMemberByGroupMemberId = async (groupMemberId: number): Promise<AxiosResponse<GroupMemberCommonDTO>> => {
        return this.axios.get(`GroupMember/GetCommonGroupMember?groupMemberId=${groupMemberId}`);
    };

    getGroupMembers = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<GroupMemberDTO>>> => {
        return this.axios.post('/groupMember/GetGroupMembers', searchParams);
    };

    getGroupMembersMicro = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainerOf<GroupMemberMicroDTO>>> => {
        return this.axios.post(`/GroupMember/GetGroupMembersMicro`, searchParams);
    };

    getApproversList = async (options: GroupMemberApproversParamDTO): Promise<AxiosResponse<GroupMemberApproverDTO[]>> => {
        return this.axios.post('/GroupMember/GetApproversList', options);
    };

    getVatRatesForCurrentCompany = async (): Promise<AxiosResponse<number[]>> => {
        return this.axios.get('VatCode/GetVatRatesForCurrentCompany');
    };
    getVatCodesItems = async (baseSearch: BaseSearch): Promise<AxiosResponse<PagedListContainer<VatCodeDTO>>> => {
        return this.axios.post('/VatCode/GetVatCodeItems', baseSearch);
    };

    addVatCode = async (vatCode: VatCodeDTO): Promise<AxiosResponse<VatCodeDTO>> => {
        return this.axios.post('/VatCode/AddVatCode', vatCode);
    };

    editVatCode = async (vatCode: VatCodeDTO): Promise<AxiosResponse<VatCodeDTO>> => {
        return this.axios.post('/VatCode/EditVatCode', vatCode);
    };

    toggleVatCodeDefault = async (vatCode: VatCodeDTO): Promise<AxiosResponse<VatCodeDTO[]>> => {
        return this.axios.post('/VatCode/ToggleVatCodeDefault', vatCode);
    };

    deleteVatCode = async (id: number): Promise<AxiosResponse<any>> => {
        return this.axios.delete(`/VatCode/DeleteVatCode/${id}`);
    };

    uploadVatCodeCSVFile = async (file: FileDTO, onUploadProgress?: UploadProgress): Promise<AxiosResponse<any>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post('/VatCode/UploadVatCodeFile', file, config);
    };

    getWaitingInvoicesCount = async (dbName: string, userGuid: string, companyGuid: string): Promise<AxiosResponse<number>> => {
        return this.axios.get('/Company/GetWaitingInvoicesCount', {
            params: {
                dbName,
                userGuid,
                companyGuid,
            },
        });
    };

    getWaitingInvoicesCounts = async (): Promise<AxiosResponse<CompanyInvoiceCountDTO[]>> => {
        return this.axios.get('/company/GetWaitingInvoicesCounts');
    };

    getWaitingInvoicesCountsForCompanyList = async (companyGuids: string[]): Promise<AxiosResponse<CompanyInvoiceCountDTO[]>> => {
        return this.axios.post('/company/GetWaitingInvoicesCounts', companyGuids);
    };

    getCompanySettings = async (companyGuid: string): Promise<AxiosResponse<SystemConfigurationDTO[]>> => {
        return this.axios.get(`company/GetCompanySettings?companyGuid=${companyGuid}`);
    };

    addCompanySystemConfiguration = async (companySettings: SystemConfigurationDTO[]): Promise<AxiosResponse<SystemConfigurationDTO[]>> => {
        return this.axios.post('/company/AddCompanySystemConfiguration', companySettings);
    };

    addOrUpdateCompanySetting = async (companySetting: SystemConfigurationDTO, skipTrimming?: boolean): Promise<AxiosResponse<SystemConfigurationDTO>> => {
        return this.axios.post(`/company/AddOrUpdateCompanySetting`, companySetting, {
            headers: {
                skipTrimming,
            },
        });
    };

    getInvoiceCustomFieldsWithoutItems = async (): Promise<AxiosResponse<InvoiceCustomFieldDTO[]>> => {
        return this.axios.get('/company/GetInvoiceCustomFieldsWithoutItems');
    };

    getTimeZones = async (): Promise<AxiosResponse<CompanyTimeZoneDTO[]>> => {
        return this.axios.get('/company/GetTimeZones');
    };

    exportVatCodesToCSV = async (restrictions: Restriction[]): Promise<AxiosResponse<void>> => {
        return this.axios.post('/VatCode/ExportVatCodesToCSV', restrictions, { responseType: 'blob' });
    };

    exportVatCodesToXls = async (restrictions: Restriction[]): Promise<AxiosResponse<void>> => {
        return this.axios.post('/VatCode/ExportVatCodesToXls', restrictions, { responseType: 'blob' });
    };
}
