export interface UserSetting {
    Name: string;
    Value: string;
}

export interface User {
    Id?: number;
    Username: string;
    FirstName: string;
    LastName: string;
    Password: string;
    BORole: number;
    BetaRole: boolean;
    Language: string;
    LastCompany?: number;
    Email: string;
    Settings?: UserSetting[];
}

export enum Modules {
    PurchaseInvoiceRegister = 2,
    Expenses,
    Archive,
    ProductItems,
    PurchaseOrders,
}

export interface CompanyFull {
    Id: number;
    Name: string;
    Database: string;
    UIAddress: string;
    RegistrationCode: string;
    AuthPhrase: string;
    KiToken: string;
    AccessGranted: boolean;
    ActiveModules: Modules[];
    IsClone: boolean;
    IsDeleteEnabled: boolean;
}

export interface CompanyShort {
    Id: number;
    Name: string;
    RegistrationCode: string;
}

export enum BackOfficeCompanyStatus {
    UNDEFINED = 0,
    ACTIVE = 1,
    ARCHIVED = 5,
    DEMO = 2,
    DEACTIVATED = 3,
    TEST = 4,
}

export enum BackOfficeDbType {
    MSSQL = 'mssql',
    POSTGRE = 'postgre',
}

export enum BackOfficeUserRole {
    NONE = 0,
    GOD_MODE = 1,
    SUPER_ADMIN = 2,
    PRODUCT_MANAGER = 3,
    RE_SELLER = 4,
    TECHNICAL_SUPPORT = 5,
}

export interface BackOfficeUserSettingDTO {
    Name: string;
    Value: string;
}

export interface BackOfficeUserCompanyDTO {
    Id: number;
    UserGuid: string;
    CompanyGuid: string;
    MembershipStart: string;
    MembershipEnd: string;
}

export interface BackOfficeUserDTO {
    UserGuid: string;
    FirstName: string;
    LastName: string;
    FullName: string;
    Username: string;
    Email: string;
    ExternalId: string;
    PersonalCode: string;
    PersonalCodeLastValidated: string;
    Password: string;
    Role: BackOfficeUserRole;
    Country: string;
    Language: string;
    LastCompany: string;
    BetaRole: boolean;
    EmailLastValidated: string;
    UserNotes: string;
    Settings: BackOfficeUserSettingDTO[];
    UserCompanies: BackOfficeUserCompanyDTO[];
    Managers: BackOfficeManagerDTO[];
    ResellerGuid: string | null;
    Reseller: BackOfficeResellerDTO | null;
}

export interface BackOfficeReportDTO {
    FileName: string;
}

export interface BackOfficeIntegratorDTO {
    IntegratorGuid: string;
    IsEnabled: boolean;
    IntegratorName: string;
    ContactPerson: string;
    Email: string;
    Phone: string;
    LastActive: string;
    Notes: string;
    WhiteListAddresses: string;
    IsKeycloakEnabled: boolean;
    KeycloakSettings: KeycloakSettingDTO;
}

export interface KeycloakSettingDTO {
    ExportApi: Endpoint[];
    ImportApi: Endpoint[];
}

export interface Endpoint {
    Enabled: boolean;
    Endpoint: string;
}

export interface BackOfficeDomainDTO {
    DomainGuid: string;
    IsEnabled: boolean;
    DomainName: string;
    IsDefault: boolean;
}

export interface BackOfficeManagerDTO {
    Id: number;
    UserGuid: string;
    CompanyGuid: string;
    User: BackOfficeUserDTO;
    Company: BackOfficeCompanyDTO;
}

export interface CompanySearchRequestParams {
    companyName?: string;
    pageSize?: number;
    lastCompanyGuid?: string;
}
export interface BackOfficeCompanyDTO {
    CompanyGuid: string;
    CompanyName: string;
    Country: string;
    RegistrationCode: string;
    VatCode: string;
    AddressLine1?: string;
    AddressLine2?: string;
    PostalCode?: string;
    City?: string;
    County?: string;
    DailyReminderTime1?: number;
    DailyReminderTime2?: number;
    DailyReportDates?: string;
    DailyReportTime1?: Date;
    DailyReportTime2?: Date;
    Status: BackOfficeCompanyStatus;
    CompanyNotes: string;
    ResellerGuid: string;
    OrganizationGuid: string;
    AuthToken: string;
    DbServerIp: string;
    DbName: string;
    DbSchema: string;
    DbType: BackOfficeDbType;
    ChildCompaniesCount: number;
    UsersCount: string; // format "int/int", e.g. "25/150"
    UserCompanies: BackOfficeUserCompanyDTO[];
    Managers: BackOfficeManagerDTO[];
    Organization: BackOfficeOrganizationDTO;
    Reseller: BackOfficeResellerDTO;
    AllowToSwitch: boolean;
    TimeZone: string;
    TimeZoneDisplay: string;
    ModifiedBy?: string;
}

export interface BackOfficeCompanyDTOExtended extends BackOfficeCompanyDTO {
    WaitingInvoiceCount?: number;
}

export interface BackOfficeOrganizationDTO {
    OrganizationGuid: string;
    OrganizationName: string;
    OrganizationNotes: string;
    Reseller: BackOfficeResellerDTO | null;
    ResellerGuid: string | null;
    AuthToken: string;
    Companies: BackOfficeCompanyDTO[];
}

export interface OrganizationShort {
    OrganizationGuid: string;
    OrganizationName: string;
    OrganizationNotes: string;
}

export interface BackOfficeResellerDTO {
    ResellerGuid: string;
    ResellerName: string;
    DomainGuid: string;
    Domain: BackOfficeDomainDTO;
    Email: string;
    ContactPerson: string;
    Phone: string;
    ResellerNotes: string;
}

export interface BackOfficeAssistantDTO {
    UserGuid: string;
    FullName: string;
    IsAssistant: boolean;
    DateFrom: string;
    DateTo: string;
}

export enum BackOfficeCompanyModules {
    PurchaseInvoiceRegister = 2,
    Expenses = 3,
    Archive = 4,
    ProductItems = 5,
    PurchaseOrders = 6,
    MobileOCR = 7,
    Digitization = 8,
    BypassFitekin = 9,
    AiAccounting = 10,
    SuppliersSync = 11,
}

export enum BackOfficeResponseErrorCodes {
    NONE = 'None',
    EXCEPTION = 'Exception',
    ITEM_NOT_FOUND = 'ItemNotFound',
    WRONG_STATUS = 'WrongStatus',
}

export interface BackOfficeResponseErrorDTO {
    ObjectId: string;
    ErrorCode: BackOfficeResponseErrorCodes;
    ErrorMessage: string;
    StackTrace: string;
}

export interface BackOfficeResponseDTO<T> {
    Success: boolean;
    Message: string;
    Errors: BackOfficeResponseErrorDTO[];
    ResultObject?: T;
}

export interface UserPasswordSetupInitiationDTO {
    UserGuid: string;
    Country?: string;
    Language?: string;
    CompanyName: string;
}

export interface UserNewCompanyAccessDTO {
    UserGuid: string;
    CompanyName: string;
}

export interface UserPasswordSetupDTO {
    VerificationToken: string;
    Password: string;
    Country: string;
}

export interface MailboxDTO {
    CompanyGuid: string;
    CompanyName: string;
    CreatedBy: string;
    CreatedDateTime: string;
    CreatorFullName: string;
    Domain: string | null;
    Enabled: boolean;
    LastModified: string;
    MailboxGuid: string;
    ModifiedBy: string | null;
    ModifierFullName: string;
    ResponseMessage: string | null;
    Username: string | null;
    IsShared: boolean;
    MailboxesCompanies: MailboxCompanyDTO[];
}

export interface MailboxCompanyDTO {
    MailboxGuid: string;
    CompanyGuid: string;
    CompanyName: string;
}

export type CompanyWithoutMailboxDTO = Pick<MailboxCompanyDTO, 'CompanyGuid' | 'CompanyName'>;
export type MailboxGuidNameDTO = { MailboxGuid: string; MailboxName: string };

export interface MailboxWorkflowDTO {
    MailBoxGuid: string;
    MailKeepDays: string;
    MailMaxAttachmentsSize: string;
    OcrEngine: string;
    GenerateOutput: string;
    InvoiceConfirmationEngine: string;
    UploadUsing: string;
    ErpName: string;
    MailKeepDayValues: string[];
    MailMaxAttachmentsSizeValues: string[];
    PdfPreprocessingValues: string[];
    InvoiceFormatValues: string[];
    OcrEngineValues: string[];
    GenerateOutputValues: string[];
    InvoiceConfirmationEngineValues: string[];
    UploadUsingValues: string[];
    ErpNameValues: string[];
    PdfPreprocessing: string | null;
    IncludeEmailPdfBody: string;
    IncludeEmailPdfBodyValues: string[];
    IncludeAttachedFileNames: boolean;
    WhitelistDomain: string | null;
    RejectionEmails: string | null;
    AddAttachmentsValues: string[];
    AddAttachments: string;
    IncludeAllLineItems: string;
    IncludeAllLineItemsValues: string[];
    JpegProcessing: string;
    JpegProcessingValues: string[];
    DigitizationSla: string;
    DigitizationSlaValues: string[];
    DigitizingRejectionEmails: string | null;
    RossumQueueId: string | null;
    RossumWorkspace: RossumWorkspaceDTO | null;
    ProcessSalesInvoices: string;
    ProcessSalesInvoicesValues: string[];
    ReplyEmail: string | null;
    InvoiceContentText: string | null;
    AddEmailSubjectToXml: string;
    AddEmailSubjectToXmlValues: string[];
    CustomizedExtensionId: string | null;
    CustomizedExtensionInformationContent: string | null;
}

export type RossumWorkspaceDTO = {
    WorkspaceId: string;
    WorkspaceName: string;
    Queues: {
        QueueId: string;
        QueueName: string;
    }[];
};

export type SaveMailboxWorkflowDTO = Pick<
    MailboxWorkflowDTO,
    | 'AddAttachments'
    | 'MailBoxGuid'
    | 'MailKeepDays'
    | 'MailMaxAttachmentsSize'
    | 'OcrEngine'
    | 'GenerateOutput'
    | 'InvoiceConfirmationEngine'
    | 'UploadUsing'
    | 'ErpName'
    | 'PdfPreprocessing'
    | 'IncludeEmailPdfBody'
    | 'IncludeAttachedFileNames'
    | 'WhitelistDomain'
    | 'RejectionEmails'
    | 'IncludeAllLineItems'
    | 'JpegProcessing'
    | 'DigitizationSla'
    | 'DigitizingRejectionEmails'
    | 'RossumQueueId'
    | 'ProcessSalesInvoices'
    | 'ReplyEmail'
    | 'InvoiceContentText'
    | 'AddEmailSubjectToXml'
    | 'CustomizedExtensionId'
    | 'CustomizedExtensionInformationContent'
>;
