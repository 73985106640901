import { cloneDeep, reject, isEqual } from 'lodash-es';
import storeAndPersistor from '../../storeConfig';

import { GroupMemberDTO, MemberRoleDTO, UserCreationDTO } from '../../services/types/ApiTypes';
import { EditUserModalFields } from '../../views/user-list/components/EditUserModal/EditUserModal';
import { UserRole } from '../constants/appConstants';
import { isSystemSetting } from './userPermissionUtil';
import { selectCurrencies } from './UserSelectors';

export interface RoleDTO {
    Id: number;
    Name: string;
}

export interface RolesDTO {
    Role: number;
    IsNew: boolean;
    Id: number;
}

export const getEmptyUser = (): GroupMemberDTO => ({
    Title: '',
    MembershipStart: new Date(),
    MembershipEnd: null,
    UserId: undefined,
    IsCompanyPrimaryContact: null,
    UserFullName: undefined,
    MemberRoles: [],
    IsActive: true,
    Substitute: undefined,
    Substitutes: [],
    Id: undefined,
    IsNew: undefined,
    Company: null,
    User: {
        FirstName: undefined,
        Email: undefined,
        LastName: undefined,
        PersonalCode: undefined,
        BOGuid: undefined,
        Password: null,
        GroupMember: null,
        IsActive: undefined,
        FullName: undefined,
        FullNameAndPersonalCode: undefined,
        Id: undefined,
        IsNew: false,
    },
    UserSettings: [],
    Name: undefined,
});

const exportManagementRoles = [UserRole.Administrator, UserRole.Accountant];
const exportManagementComboRoles = [UserRole.Processor, UserRole.CompletingWorkflow];

export function getRoles() {
    const roles = [
        { Id: 0, Name: 'controller.groupMemberController.Administrator' },
        { Id: 1, Name: 'controller.groupMemberController.Approver' },
        { Id: 2, Name: 'controller.groupMemberController.Assigner' },
        { Id: 3, Name: 'controller.groupMemberController.Completing_Workflow' },
        { Id: 5, Name: 'controller.groupMemberController.Auditor' },
        { Id: 8, Name: 'controller.groupMemberController.ExportManager' },
        { Id: 12, Name: 'controller.groupMemberController.Accountant' },
    ];

    if (isSystemSetting('IsExpensesModuleActive')) {
        roles.push({ Id: 13, Name: 'controller.groupMemberController.ExpenseCreator' });
    }

    if (isSystemSetting('IsArchiveModulActive')) {
        roles.push({ Id: 9, Name: 'controller.groupMemberController.ArchiveViewer' });
    }

    if (isSystemSetting('IsPurchaseOrdersModulActive')) {
        roles.push({ Id: 10, Name: 'controller.groupMemberController.PurchaseOrdersUser' });
        roles.push({ Id: 11, Name: 'controller.groupMemberController.PurchaseOrdersAdministrator' });
    }
    return roles;
}

export function hasRole(r: RoleDTO, values: RolesDTO[]) {
    return !!values.find((role) => role.Role === r.Id);
}

export const toggleRole = (r: RoleDTO, values: GroupMemberDTO | EditUserModalFields, callback: (values: GroupMemberDTO | EditUserModalFields) => void) => {
    let newRoles = cloneDeep(values.MemberRoles);
    if (hasRole(r, newRoles)) {
        const revokeExportManagerRole = exportManagementRoles.includes(r.Id) && newRoles.filter((role) => exportManagementRoles.includes(role.Role)).length === 1;
        if (revokeExportManagerRole) {
            // Revoking Administrator/Accountant roles from User should automatically revoke Export manager role
            newRoles = reject(newRoles, (role) => [UserRole.ExportManager, r.Id].includes(role.Role));
        } else if (r.Id === UserRole.Processor) {
            // Revoking Assigner role from User should automatically revoke Completing workflow role
            newRoles = reject(newRoles, (role) => [...exportManagementComboRoles, UserRole.ExportManager].includes(role.Role));
        } else {
            newRoles = reject(newRoles, (role) => role.Role === r.Id);
        }
    } else {
        newRoles.push({
            Role: r.Id,
            IsNew: undefined,
            Id: undefined,
        });
    }
    values.MemberRoles = [...newRoles];
    callback({ ...values, MemberRoles: [...newRoles] });
};

// Method from UserAddView. NB: some logic from here might be useful. TODO: check business requirements.
// TODO: Remove if not needed
// export function toggleRole(r: { Id: number; Name: string }, formik: FormikProps<EditUserModalFields>) {
//     let newRoles = cloneDeep(formik.values.MemberRoles);
//     console.log('Formik roles', formik.values.MemberRoles);
//     const userRoles: UserRole[] = formik.values.MemberRoles.map((r) => r.Role);

//     if (hasRole(r, formik.values.MemberRoles)) {
//         if (r.Id === UserRole.Administrator) {
//             // Revoking Administrator role from User should automatically revoke Export manager role
//             newRoles = reject(newRoles, (role) => {
//                 if (
//                     isEqual(
//                         userRoles.filter((r) => exportManagementComboRoles.includes(r)),
//                         exportManagementComboRoles,
//                     )
//                 ) {
//                     return [UserRole.Administrator].includes(role.Role);
//                 }
//                 return [UserRole.ExportManager, UserRole.Administrator].includes(role.Role);
//             });
//         } else if (r.Id === UserRole.Processor) {
//             // Revoking Assigner role from User should automatically revoke Completing workflow role
//             // If user has also Export Manager role, then this should also be automatically removed, when there is not Administrator role present
//             newRoles = reject(newRoles, (role) => {
//                 if (userRoles.includes(UserRole.Administrator)) {
//                     return [UserRole.CompletingWorkflow, UserRole.Processor].includes(role.Role);
//                 }
//                 return [UserRole.ExportManager, UserRole.CompletingWorkflow, UserRole.Processor].includes(role.Role);
//             });
//         } else if (r.Id === UserRole.CompletingWorkflow && difference([UserRole.ExportManager, UserRole.CompletingWorkflow, UserRole.Processor], userRoles).length === 0) {
//             // If user has ExportManager role, Completing workflow role and Approver role. Revoking Completing workflow role from User should automatically revoke ExportManager role
//             newRoles = reject(newRoles, (role) => {
//                 if (userRoles.includes(UserRole.Administrator)) {
//                     return UserRole.CompletingWorkflow === role.Role;
//                 }
//                 return [UserRole.ExportManager, UserRole.CompletingWorkflow].includes(role.Role);
//             });
//         } else {
//             newRoles = reject(newRoles, (role) => role.Role === r.Id);
//         }
//     } else {
//         newRoles.push({
//             Role: r.Id,
//             IsNew: undefined,
//             Id: undefined,
//         });
//     }
//     // formik.setFieldValue('MemberRoles', newRoles);
//     formik.values.MemberRoles = [...newRoles];
//     formik.setValues({ ...formik.values, MemberRoles: newRoles });
// }

export function isRoleDisabled(r: { Id: number; Name: string }, values: MemberRoleDTO[]): boolean {
    if (![UserRole.ExportManager, UserRole.CompletingWorkflow].includes(r.Id)) {
        return false;
    }
    const userRoles: UserRole[] = values.map((r) => r.Role);

    const userHasRequiredRolesForExportManager =
        userRoles.some((role) => exportManagementRoles.includes(role)) ||
        isEqual(
            userRoles.filter((r) => exportManagementComboRoles.includes(r)),
            exportManagementComboRoles,
        );

    const isExportManagerRoleDisabled = r.Id === UserRole.ExportManager && !userHasRequiredRolesForExportManager;
    const isCompletingWorkflowRoleDisabled = r.Id === UserRole.CompletingWorkflow && !values.find((role) => role.Role === UserRole.Processor);

    return isExportManagerRoleDisabled || isCompletingWorkflowRoleDisabled;
}

export const getEmptyUserCreation = (): UserCreationDTO => ({
    Country: undefined,
    FirstName: undefined,
    LastName: undefined,
    PersonalCode: undefined,
    Email: undefined,
    MembershipStart: new Date(),
    MembershipEnd: undefined,
    MemberRoles: [],
    MonetaryCurrency: undefined,
    MonetaryLimit: undefined,
});

export const getCurrencyName = (code: string): string => {
    const store = storeAndPersistor.store;

    return selectCurrencies(store.getState()).find((e) => e.Code === code)?.Description;
};
