import React from 'react';
import { cloneDeep, groupBy, isEmpty, values } from 'lodash-es';
import { WithTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dispatchable1 } from 'redux-dispatchers';

import { isAuthorized } from '../../../../common/user/userPermissionUtil';
import { BaseStatefulComponent } from '../../../../components/BaseStatefulComponent';
import { ToggleContent, ToggleContentType } from '../../../../components/ToggleContent/ToggleContent';
import { Typography } from '../../../../components/Ui/Typography';
import { ApproverPerStep, GroupMemberDTO, InvoiceDTO, HistoryDTO, ReplaceTasksInWorkflow, SystemConfigurationDTO, TaskDTO } from '../../../../services/types/ApiTypes';
import { ICONS, IconSize } from '../../../../components/Icon/Icon';
import { Button, ButtonType } from '../../../../components/Buttons/Button';
import { ActionBlock } from './components/ActionBlock';
import { InvoiceStatus } from '../../../../common/constants/appConstants';
import api from '../../../../services/ApiServices';
import { ConfirmationFlowActions } from '../../../../common/constants/invoiceConstants';
import ModalConfirm, { ConfirmButtonStyle } from '../../../../components/Modal/ModalConfirm';
import { User } from '../../../../services/ApiClient';
import { createDataId } from '../../../../common/utils/dataId';

import { ConfirmationEditMode } from './components/ConfirmationEditMode';
import GroupTasks from './components/GroupTasks';
import { addCurrentUserAsFirst, canUserEditInvoiceConfirmation, createInvoiceAction, userStatusInConfirmationFlow, validateInvoiceConfirm } from './InvoiceConfirmationsHelper';
import { LoadableData } from 'src/common/utils/LoadableData';
import { getDifferenceInMonths } from '../../../../common/utils/datetime';
import { CompanySettingStatus } from '../../../settings/company-settings/components/Settings/CompanySettingsListHelper';

import './InvoiceConfirmations.scss';

export interface Props {
    invoice: InvoiceDTO;
    invoiceHistoryLoadable: LoadableData<HistoryDTO[], string>;
    userData: User;
    groupMembers: GroupMemberDTO[];
    invoiceLoading: boolean;
    getInvoiceData: (invoiceId: number) => void;
    getMinimalInvoiceData: (invoiceId: number) => void;
    isLoading: boolean;
    isEditing: boolean;
    companySetting?: SystemConfigurationDTO[];

    // TODO: remove this when rewrite whole invoice detail page
    $rootScope: any;
    approveInvoice: any;
}

export enum TaskType {
    Default = 'Default',
    AddBeforeMe = 'AddBeforeMe',
    AddAfterMe = 'AddAfterMe',
    AddParallel = 'AddParallel',
    AddNextApprover = 'AddNextApprover',
    RemoveStep = 'RemoveStep',
}

export interface DispatchProps {
    setConfirmationFlowLoading: Dispatchable1<boolean>;
    setIsConfirmationFlowEditing: Dispatchable1<boolean>;
    setInvalidTransactionRows: Dispatchable1<number[]>;
    getCompanyGroupMembers: Dispatchable1<boolean>; // to force-fetch Company GroupMembers when freshly added user (outside of this session) is used inside Confirmation Flow
}

export interface GroupTasksDTO {
    type: TaskType;
    group?: TaskDTO[];
    step?: number;
}
export type InvoiceConfirmationsProps = Props & DispatchProps & WithTranslation;

export interface InvoiceConfirmationsState {
    IsDuplicate: boolean;
    hasTransactionRow: boolean;
    groupTasks: GroupTasksDTO[];
    groupTasksEdit: GroupTasksDTO[];
    groupTasksOriginal: GroupTasksDTO[];
    isModalConfirmExitEditModeOpen: boolean;
    approversPerSteps: ApproverPerStep[];
}

const dataId = 'confirmation-flow';

class InvoiceConfirmations extends BaseStatefulComponent<InvoiceConfirmationsProps, InvoiceConfirmationsState> {
    constructor(props: InvoiceConfirmationsProps) {
        super(props);
        this.state = {
            IsDuplicate: false,
            hasTransactionRow: true,
            groupTasks: [],
            groupTasksEdit: [],
            groupTasksOriginal: [],
            isModalConfirmExitEditModeOpen: false,
            approversPerSteps: [],
        };
    }

    invoiceTransactionRow = async (invoiceId: number) => {
        const response = await api.invoice.getInvoiceTransactionRowsVatRates(invoiceId);
        if (response.data.length === 0) {
            this.setState({
                hasTransactionRow: false,
            });
        } else {
            this.setState({
                hasTransactionRow: true,
            });
        }
    };

    componentWillReceiveProps(nextProps: InvoiceConfirmationsProps) {
        if ((nextProps.invoice !== this.props.invoice || (nextProps.invoice?.Workflow?.Tasks?.length > 0 && this.state.groupTasks?.length === 0)) && nextProps.invoice !== undefined) {
            if (nextProps.invoice.IsDuplicate) {
                this.setState({ IsDuplicate: true });
            } else {
                this.setState({ IsDuplicate: false });
                /**
                 * It is easier to handle the loading of GroupMembers names for the Workflow here than in AngularJS code.
                 */
                const tasks = nextProps.invoice?.Workflow?.Tasks;
                const groupTasks = values(groupBy(tasks, 'OrderNo'));
                if (groupTasks) {
                    this.setState({
                        groupTasks: this.getTaskGroup(groupTasks),
                    });
                }
            }
            if (this.props.isEditing) {
                this.props.setIsConfirmationFlowEditing(false);
                this.setState({
                    groupTasksEdit: [],
                });
            }
            this.props.setConfirmationFlowLoading(false);
            this.invoiceTransactionRow(nextProps.invoice.Id);
        }
        if (nextProps.invoice?.Workflow?.ApproversPerSteps !== this.props.invoice?.Workflow?.ApproversPerSteps && this.state.approversPerSteps !== nextProps.invoice?.Workflow?.ApproversPerSteps) {
            this.setNewApproversPerSteps(nextProps.invoice?.Workflow?.ApproversPerSteps || []);
        }
    }

    setNewApproversPerSteps = (approvers: ApproverPerStep[]) => {
        this.setState({ approversPerSteps: approvers });
    };

    getTaskGroup = (groupTasks: Array<TaskDTO[]>) => {
        return groupTasks.map((group) => ({
            type: TaskType.Default,
            group,
        }));
    };

    hasTaskAddBeforeOrAfter = (i: number, taskType: TaskType) => {
        const { groupTasks } = this.state;
        if (taskType === TaskType.AddBeforeMe) {
            return groupTasks[i - 1]?.type === TaskType.AddBeforeMe;
        }
        return groupTasks[i + 1]?.type === TaskType.AddAfterMe;
    };

    addApproverBeforeOrAfter = (taskItem: TaskDTO, action: TaskType) => {
        const currentConfirmerIndex = this.state.groupTasks.findIndex((group) => group.group && group.group.find((g) => g.Id === taskItem.Id)); // find index of group that has the confirmer that matches taskItem.Id
        const nextIndex = action === TaskType.AddBeforeMe ? currentConfirmerIndex : action === TaskType.AddAfterMe && currentConfirmerIndex + 1; // step index to insert the input
        const cloneGroupTasks = [...this.state.groupTasks];
        cloneGroupTasks.splice(nextIndex, 0, {
            type: action,
            group: cloneGroupTasks[currentConfirmerIndex]?.group?.map((t) => ({
                ...t,
                displayAddAfterMeProp: false,
                displayAddBeforeMeProp: false,
                isCurrentConfirmer: false,
                Id: undefined,
            })),
            step: TaskType.AddBeforeMe ? taskItem.OrderNo : taskItem.OrderNo + 1,
        });

        this.setState({
            groupTasks: cloneGroupTasks,
        });
    };

    cancelAddApproverBefore = (task: GroupTasksDTO) => {
        const cloneGroupTasks = [...this.state.groupTasks];
        this.setState({
            groupTasks: cloneGroupTasks.filter((t) => !(t.type === task.type && t.step === task.step)), // filter out step that have a matching type and step number
        });
    };

    editWorkFlows = () => {
        if (this.props.isEditing) {
            return this.setState({ isModalConfirmExitEditModeOpen: true });
        }

        const groupTasksComplete: GroupTasksDTO[] = [];
        const groupTasksNotComplete: GroupTasksDTO[] = [];
        const groupTasksOriginal: GroupTasksDTO[] = cloneDeep(this.state.groupTasks);
        this.state.groupTasks.map((item) => {
            if (item.group[0].Completed) {
                groupTasksComplete.push(item);
            } else {
                groupTasksNotComplete.push(item);
            }
        });
        this.setState({ groupTasks: groupTasksComplete, groupTasksEdit: groupTasksNotComplete, groupTasksOriginal });
        this.props.setIsConfirmationFlowEditing(true);
    };

    confirmExitEditWorkflows = () => {
        this.setState({ groupTasks: this.state.groupTasksOriginal, groupTasksEdit: [], isModalConfirmExitEditModeOpen: false });
        this.props.setIsConfirmationFlowEditing(false);
    };

    saveWorkflowChanged = async (approversPerSteps?: ApproverPerStep[]) => {
        let workflowTasksEdited = [...this.state.groupTasks, ...this.state.groupTasksEdit];
        const { invoice, groupMembers, getCompanyGroupMembers } = this.props;
        let confirmFirst = false;
        // add current user as assigner
        if (invoice && invoice.Status === InvoiceStatus.New && invoice?.Workflow?.Tasks.length === 0) {
            workflowTasksEdited = addCurrentUserAsFirst(workflowTasksEdited, this.props.userData);
            confirmFirst = true;
        }
        workflowTasksEdited.forEach((group, iGroup) => {
            group.group.forEach((task) => {
                task.OrderNo = iGroup + 1;
            });
        });
        /**
         * extract this into a variable for later to go through the tasks and scan for usages of freshly added users (added outside of this session)
         */
        const editedWorkflowTasks = workflowTasksEdited.map((t) => t.group).reduce((previous, current) => [...previous, ...current]);
        const replaceTasksInWorkflow: ReplaceTasksInWorkflow = {
            Id: invoice ? invoice.WorkflowId : null,
            InvoiceId: invoice ? invoice.Id : null,
            Tasks: editedWorkflowTasks,
            ApproversPerSteps: approversPerSteps || this.state.approversPerSteps,
        };
        this.props.setConfirmationFlowLoading(true);
        /**
         * find inside workflow tasks if freshly added user is in use
         */
        const newlyAddedGroupMemberUsed = editedWorkflowTasks.find((task) => !groupMembers.find((gm) => task.GroupMemberId === gm.Id));
        if (newlyAddedGroupMemberUsed) {
            /**
             * if found usage of freshly added user whose GM is not yet in the store, force-fetch Company GroupMembers
             */
            await getCompanyGroupMembers(true);
        }

        await api.invoice.replaceTasks(replaceTasksInWorkflow, confirmFirst);

        this.props.getMinimalInvoiceData(invoice.Id);
        this.props.setIsConfirmationFlowEditing(false);
    };

    getNumberOfCompletedGroupTasks = () => this.state.groupTasks.filter((t) => t.group[0].Completed).length;

    removeGroup = (index: number) => {
        this.state.groupTasksEdit.splice(index, 1);
        const groupTasksEdit = [...this.state.groupTasksEdit];
        // shift orderNr up for all the groups after the deleted group
        groupTasksEdit.forEach((gt, i) => {
            if (i >= index) {
                gt.group = gt.group.map((g) => ({ ...g, OrderNo: g.OrderNo - 1 }));
            }
        });
        this.setState({
            groupTasksEdit: [...this.state.groupTasksEdit],
        });
    };

    isTransactionRowsCheckEnabled = (companySetting: SystemConfigurationDTO[]) => companySetting.find((c) => c.Name === 'IsTransactionRowsCheckEnabled' && c.Value === CompanySettingStatus.Enabled);

    getWasExportedLess1YearAgo = (): boolean => {
        const exportActions = this.props.invoiceHistoryLoadable?.payload?.filter((action) => {
            return action.ActionId === 5;
        });
        if (!exportActions || exportActions.length === 0 || !exportActions[0].LogDate) {
            return true; // invoice was not exported yet
        }
        const exportDate = new Date(exportActions[0].LogDate);
        if (exportDate instanceof Date) {
            return Math.abs(getDifferenceInMonths(new Date(), exportDate)) < 12;
        }
        return true; // this should not happen if the date is correct
    };

    setInvalidTransactionRows = (rows: number[]) => {
        this.props.setInvalidTransactionRows(rows);
    };

    render() {
        const { t, userData, approveInvoice, invoice, isLoading, companySetting, isEditing, setConfirmationFlowLoading } = this.props;
        const isTransactionRowsCheckEnabled = this.isTransactionRowsCheckEnabled(companySetting);
        const { approversPerSteps, groupTasks, groupTasksEdit } = this.state;
        const isWorkflowSet = !!groupTasks?.length;
        const showDuplicatesMessage = this.state.IsDuplicate;
        const showMandatoryTransactionRowsMessage = !isWorkflowSet && !this.state.hasTransactionRow;
        const contentClasses = classNames('confirmation__content', { 'confirmation__content--read-only': showDuplicatesMessage || showMandatoryTransactionRowsMessage });
        const isInvoiceApproved = invoice ? invoice.Status === InvoiceStatus.Exported || invoice.Status === InvoiceStatus.NotForExport || invoice.Status === InvoiceStatus.PendingExport : false;
        const { isUserInsideCurrentStep, isUserInsideCompletedStep, isUserInsideUpcomingStep, canUserSkipConfirmation } = userStatusInConfirmationFlow(
            userData,
            groupTasks,
            invoice?.Workflow?.ApproversPerSteps,
        );
        const canEditConfirmationFlow = canUserEditInvoiceConfirmation(invoice, isUserInsideCurrentStep, isUserInsideCompletedStep);

        return (
            !isEmpty(invoice) && (
                <ToggleContent
                    dataId={createDataId(dataId, 'expand-content')}
                    className="confirmation__container"
                    type={ToggleContentType.CONTENT_BLOCK}
                    loading={isLoading}
                    toggleContent={
                        <Typography variant="h2" element="span" className="confirmation__header">
                            <span className="confirmation__title">{t('component.confirmerFlow.heading')}</span>
                            {!showMandatoryTransactionRowsMessage && !showDuplicatesMessage && canEditConfirmationFlow && (
                                <Button
                                    buttonType={ButtonType.ICON_SQUARE}
                                    className={`confirmation__header-button ${isEditing ? 'confirmation__header-button--close' : 'confirmation__header-button--edit'}`}
                                    icon={isEditing ? ICONS.CLOSE_BOLD : ICONS.EDIT}
                                    iconSize={IconSize.SM}
                                    onClick={() => this.editWorkFlows()}
                                    dataId={createDataId(dataId, 'edit-button')}
                                />
                            )}
                        </Typography>
                    }
                    defaultOpen={true}
                    alwayOpen={isEditing}
                    isContentReadOnly={false}
                    overflowWhenOpen="unset"
                >
                    <section className="confirmation">
                        <div className={contentClasses}>
                            {showDuplicatesMessage && t('component.ConfirmationFlow.message.duplicate')}
                            {showMandatoryTransactionRowsMessage && !showDuplicatesMessage && t('component.ConfirmationFlow.message.NoTransactionRows')}
                            {!showMandatoryTransactionRowsMessage && !showDuplicatesMessage && (
                                <>
                                    {!isEditing && (
                                        <div className="action-block">
                                            <ActionBlock
                                                canUserSkipConfirmation={canUserSkipConfirmation}
                                                dataId={createDataId(dataId, 'action-block')}
                                                invoice={this.props.invoice}
                                                t={t}
                                                userData={userData}
                                                approveInvoice={approveInvoice}
                                                $rootScope={this.props.$rootScope}
                                                isUserInsideCurrentStep={isUserInsideCurrentStep}
                                                isUserInsideCompletedStep={isUserInsideCompletedStep}
                                                isUserInsideUpcomingStep={isUserInsideUpcomingStep}
                                                setLoading={setConfirmationFlowLoading}
                                                setInvalidRows={this.setInvalidTransactionRows}
                                                isTransactionRowsCheckEnabled={!!isTransactionRowsCheckEnabled}
                                            />
                                        </div>
                                    )}
                                    <GroupTasks
                                        approversPerSteps={approversPerSteps}
                                        dataId={createDataId(dataId, 'group-tasks')}
                                        addApprover={this.addApproverBeforeOrAfter}
                                        userData={userData}
                                        hasAddBefore={(i: number) => this.hasTaskAddBeforeOrAfter(i, TaskType.AddBeforeMe)}
                                        hasAddAfter={(i: number) => this.hasTaskAddBeforeOrAfter(i, TaskType.AddAfterMe)}
                                        invoice={invoice}
                                        approveInvoice={approveInvoice}
                                        groupTasks={groupTasks}
                                        cancelAddApproverBefore={this.cancelAddApproverBefore}
                                    />
                                </>
                            )}
                            {isAuthorized('CanChangeInvoiceStatus', userData) && isInvoiceApproved && this.getWasExportedLess1YearAgo() && (
                                <Button
                                    buttonType={ButtonType.PRIMARY}
                                    dataId={createDataId(dataId, 'reassign-to-last')}
                                    className="button button--secondary confirmation__button-re-assign"
                                    onClick={() => {
                                        if (validateInvoiceConfirm(invoice, ConfirmationFlowActions.Reopen)) {
                                            setConfirmationFlowLoading(true);
                                            const action = createInvoiceAction(ConfirmationFlowActions.Reopen, invoice.Id, '', undefined);
                                            action.NextConfirmerGroupMemberOrWorkflowTemplateId = -1;
                                            approveInvoice(action);
                                        }
                                    }}
                                >
                                    {t('component.additionalInfo.setAsApproved')}
                                </Button>
                            )}
                            {isEditing && (
                                <ConfirmationEditMode
                                    approversPerSteps={approversPerSteps}
                                    invoice={invoice}
                                    userData={userData}
                                    t={t}
                                    groupTasksEdit={groupTasksEdit}
                                    numberOfCompletedTasks={this.getNumberOfCompletedGroupTasks()}
                                    updateGroupTaskEditing={(groupTasksEdit: GroupTasksDTO[]) =>
                                        this.setState({
                                            groupTasksEdit,
                                        })
                                    }
                                    $rootScope={this.props.$rootScope}
                                    saveWorkflowChanged={this.saveWorkflowChanged}
                                />
                            )}
                        </div>
                    </section>
                    <ModalConfirm
                        dataId={createDataId(dataId, 'editEditing')}
                        title={t('component.confirmationFlow.modal.confirmExitEditing.title')}
                        open={this.state.isModalConfirmExitEditModeOpen}
                        onClose={() => this.setState({ isModalConfirmExitEditModeOpen: false })}
                        cancelActions={this.confirmExitEditWorkflows}
                        handleActions={() => {
                            // TODO: needs update when user decides to save modyfied XofY property
                            this.saveWorkflowChanged();
                            this.setState({ isModalConfirmExitEditModeOpen: false });
                        }}
                        confirmButtonStyle={ConfirmButtonStyle.PRIMARY}
                        confirmText={t('views.invoice.partials.invoiceInformation.Save')}
                        cancelText={t('component.confirmationFlow.modal.confirmExitEditing.Close')}
                    >
                        {t('component.confirmationFlow.modal.confirmExitEditing.content')}
                    </ModalConfirm>
                </ToggleContent>
            )
        );
    }
}

export default InvoiceConfirmations;
