import constants from '../src/common/constants/appConstants';
import { selectCurrentCompanySettings } from '../src/common/company/CompanySelectors';
import { formatPrecisionScales } from '../src/components/MonetaryPrecisionScales/hooks/useMonetaryScalesFormat';

"use strict";

var app = angular.module("dstreamApp.directives", []);

/* TODO: Deprecated */
app.directive("ngModelOnblur", function () {
  return {
    restrict: "AE",
    require: "ngModel",
    priority: 1, // needed for angular 1.2.x
    link: function (scope, elm, attr, ngModelCtrl) {
      if (attr.type === "radio" || attr.type === "checkbox") return;

      elm.unbind("input").unbind("keydown").unbind("change");
      elm.bind("blur", function () {
        scope.$apply(function () {
          ngModelCtrl.$setViewValue(elm.val());
        });
      });
    }
  };
});

app.directive("enterKeyPress", function () {
  return function (scope, element, attrs) {
    element.bind("keydown keypress", keypress);

    function keypress (event) {
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.enterKeyPress);
        });

        event.preventDefault();
      }
    }

    scope.$on("$destroy", function () {
      element.unbind("keydown keypress", keypress);
    });
  };
});

app.directive("doOnBlur", function () {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ctrl) {
      element.bind("blur", function (event) {
        scope.$eval(attrs.doOnBlur);

        event.preventDefault();
      });
    }
  };
});

app.directive("doOnBlurIfChange", function () {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ctrl) {
      var oldValue = null;
      element.bind("focus", function (event) {
        oldValue = ctrl.$modelValue;
      });

      element.bind("blur", function (event) {
        if (oldValue != ctrl.$modelValue) {
          scope.$eval(attrs.doOnBlurIfChange);
        }

        event.preventDefault();
      });
    }
  };
});

app.directive("ngcBlur", [
  "$parse",
  function ($parse) {
    return function (scope, element, attr) {
      var fn = $parse(attr["ngcBlur"]);
      element.bind("blur", function (event) {
        scope.$apply(function () {
          fn(scope, {$event: event});
        });
      });
    };
  }
]);

app.directive("validNumber", function () {
  return {
    require: "?ngModel",
    link: function (scope, element, attrs, ngModelCtrl) {
      if (!ngModelCtrl) {
        return;
      }

      ngModelCtrl.$parsers.push(function (val) {
        if (val === undefined) return "";

        var clean = val.replace(/[^0-9]+/g, "");
        if (val !== clean) {
          ngModelCtrl.$setViewValue(clean);
          ngModelCtrl.$render();
        }
        return clean;
      });

      element.bind("keypress", function (event) {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
      });
    }
  };
});

app.directive("onlyDigits", function () {
  return {
    restrict: "A",
    require: "?ngModel",
    link: function (scope, element, attrs, ngModel) {
      if (!ngModel) return;
      ngModel.$parsers.unshift(function (inputValue) {
        inputValue = inputValue.replace(",", ".");
        var digits = inputValue.split("").filter(function (s) { return ((!isNaN(s) && s != " ") || s == "." || s == "-"); }).join("");
        var count = 0;
        for (var i = count = 0; i < digits.length; count += +("." === digits[i++]))
          if (count > 1) digits = digits.substring(0, digits.length - 1);
        ngModel.$setViewValue(digits);
        ngModel.$render();
        return digits.toString();
      });
    }
  };
});

app.directive("onlyDigitsTwoAfterComma", function () {
  return {
    restrict: "A",
    require: "?ngModel",
    link: function (scope, element, attrs, ngModel) {
      if (!ngModel) return;
      ngModel.$parsers.unshift(function (inputValue) {
        inputValue = inputValue.replace(",", ".");
        var digits = inputValue.split("").filter(function (s) { return ((!isNaN(s) && s != " ") || s == "." || s == "-"); }).join("");
        var count = 0;
        for (var i = count = 0; i < digits.length; count += +("." === digits[i++]))
          if (count > 1) digits = digits.substring(0, digits.length - 1);
        var commaPlace = digits.indexOf(".");
        if (commaPlace > -1) { digits = digits.substring(0, commaPlace + 3); }
        ngModel.$setViewValue(digits);
        ngModel.$render();
        return digits.toString();
      });
    }
  };
});

app.directive("onlyDigitsFourAfterComma", function () {
  return {
    restrict: "A",
    require: "?ngModel",
    link: function (scope, element, attrs, ngModel) {
      if (!ngModel) return;
      ngModel.$parsers.unshift(function (inputValue) {
        inputValue = inputValue.replace(",", ".");
        var digits = inputValue.split("").filter(function (s) { return ((!isNaN(s) && s != " ") || s == "." || s == "-"); }).join("");
        var count = 0;
        for (var i = count = 0; i < digits.length; count += +("." === digits[i++]))
          if (count > 1) digits = digits.substring(0, digits.length - 1);
        var commaPlace = digits.indexOf(".");
        if (commaPlace > -1) { digits = digits.substring(0, commaPlace + 5); }
        ngModel.$setViewValue(digits);
        ngModel.$render();
        return digits.toString();
      });
    }
  };
});

app.directive("onlyDigitsBeforeAfterComma", function () {
  return {
    restrict: "A",
    require: "?ngModel",
    link: function (scope, element, attrs, ngModel) {
      if (!ngModel) return;
      ngModel.$formatters.unshift(function (inputValue) {
        var digitsBeforeComma = Number(attrs.onlyDigitsBeforeAfterComma.split(",")[0]);
        var digitsAfterComma = Number(attrs.onlyDigitsBeforeAfterComma.split(",")[1]);
        inputValue = inputValue.replace(",", ".");
        var digits = inputValue.split("").filter(function (s) { return ((!isNaN(s) && s != " ") || s == "." || s == "-"); }).join("");
        var count = 0;
        for (var i = count = 0; i < digits.length; count += +("." === digits[i++]))
          if (count > 1) digits = digits.substring(0, digits.length - 1);
        var commaPlace = digits.indexOf(".");
        if (commaPlace > -1) {
          if ((commaPlace + 1) > digitsBeforeComma) {
            digits = digits.substring(0, digitsBeforeComma) + digits.substring(commaPlace, commaPlace + digitsAfterComma + 1);
          }
          else {
            digits = digits.substring(0, commaPlace + digitsAfterComma + 1);
          }
        } else {
          digits = digits.substring(0, digitsBeforeComma);
        }
        ngModel.$setViewValue(digits);
        ngModel.$render();
        return digits.toString();
      });
      ngModel.$parsers.unshift(function (inputValue) {
        var digitsBeforeComma = Number(attrs.onlyDigitsBeforeAfterComma.split(",")[0]);
        var digitsAfterComma = Number(attrs.onlyDigitsBeforeAfterComma.split(",")[1]);
        inputValue = inputValue.replace(",", ".");
        var digits = inputValue.split("").filter(function (s) { return ((!isNaN(s) && s != " ") || s == "." || s == "-"); }).join("");
        var count = 0;
        for (var i = count = 0; i < digits.length; count += +("." === digits[i++]))
          if (count > 1) digits = digits.substring(0, digits.length - 1);
        var commaPlace = digits.indexOf(".");
        if (commaPlace > -1) {
          if ((commaPlace + 1) > digitsBeforeComma) {
            digits = digits.substring(0, digitsBeforeComma) + digits.substring(commaPlace, commaPlace + digitsAfterComma + 1);
          }
          else {
            digits = digits.substring(0, commaPlace + digitsAfterComma + 1);
          }
        } else {
          digits = digits.substring(0, digitsBeforeComma);
        }
        ngModel.$setViewValue(digits);
        ngModel.$render();
        return digits.toString();
      });
    }
  };
});

app.directive('onlyDigitsNetVatTotal', ['$ngRedux', function(ngRedux) {
    return {
        restrict: 'A',
        require: '?ngModel',

        link: function(scope, element, attrs, ngModel) {
            if (!ngModel) return;
            ngModel.$parsers.unshift(function(inputValue) {
                element.on("blur", handleSave);
                element.on("keydown", handleKeyDown);

                inputValue = inputValue.replace(",", ".");
                const settingsList = selectCurrentCompanySettings(ngRedux.getState());
                const setting = settingsList.find((setting) => setting.Name === 'MonetaryNetVatTotalPrecision');
                const decimalScale = Number(setting.Value || 2);

                let digits = inputValue.split('').filter(function(s) {
                    return ((!isNaN(s) && s !== ' ') || s === '.' || s === '-');
                }).join('');
                const [integerPart = '0', ...decimalParts] = digits.split('.');
                function handleSave() {
                    const cleanedIntegerPart = integerPart.replace(/^0+(?!\.|$)/, '') || '0';
                    const decimalPart = (decimalParts.join('').replace(/\.+/g, '') || '0').padEnd(Number(decimalScale), '0').slice(0, Number(decimalScale));
                    digits = `${cleanedIntegerPart}.${decimalPart}`;
                    digits = formatPrecisionScales(digits, {decimalScale})
                    ngModel.$setViewValue(digits);
                    ngModel.$render();
                    return digits.toString();
                }
                function handleKeyDown(event) {
                  if (event.which === 13) {
                    handleSave();
                  }
                }
                const cleanedIntegerPart = integerPart.replace(/^0+(?!\.|$)/, '').substring(0, 18);
                const decimalPart = (decimalParts.join('').replace(/\.+/g, '')).substring(0, decimalScale);
                const commaPlace = digits.indexOf(".");
                digits = commaPlace !== -1 ? `${cleanedIntegerPart}.${decimalPart}` : cleanedIntegerPart;
                ngModel.$setViewValue(digits);
                ngModel.$render();
                return digits.toString();
            });
        },
    };
}]);


app.directive("validDay", function () {
  return {
    require: "ngModel",
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser (text) {
        if (!text) {
          return text;
        }
        var transformedInput = text.toString().replace(/[^0-9]/g, "");
        transformedInput = transformedInput < 1 ? 1 : transformedInput;
        if (transformedInput !== text) {
          ngModelCtrl.$setViewValue(transformedInput);
          ngModelCtrl.$render();
        }
        return transformedInput;  // or return Number(transformedInput)
      }

      element.bind("blur", function () {
        if (!ngModelCtrl.$modelValue) {
          ngModelCtrl.$setViewValue(1);
          ngModelCtrl.$render();
        }
      });
      element.bind("keypress", function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          angular.element(element).next(".date-range-modal__input-button").click();
        }
      });
      ngModelCtrl.$parsers.push(fromUser);
    }
  };
});

app.directive("numberFormat", [
  "$filter",
  "$parse",
  function ($filter, $parse) {
    return {
      require: "ngModel",
      link: function (scope, element, attrs, ngModelController) {
        var decimals = $parse(attrs.decimals)(scope);

        ngModelController.$parsers.push(function (data) {
          // Attempt to convert user input into a numeric type to store
          // as the model value (otherwise it will be stored as a string)
          // NOTE: Return undefined to indicate that a parse error has occurred
          //       (i.e. bad user input)
          var parsed = parseFloat(data);
          return !isNaN(parsed) ? parsed : undefined;
        });

        ngModelController.$formatters.push(function (data) {
          //convert data from model format to view format
          return $filter("number")(data, decimals); //converted
        });

        element.bind("focus", function () {
          element.val(ngModelController.$modelValue);
        });

        element.bind("blur", function () {
          // Apply formatting on the stored model value for display
          var formatted = $filter("number")(ngModelController.$modelValue, decimals);
          element.val(formatted);
        });
      }
    };
  }
]);

app.directive("autoExpandAccordion", function ($timeout) {
  return {
    restrict: "A",
    scope: {
      handleExpand: "&"
    },
    link: function (scope, element, attrs) {
      scope.$watch(function () {
          return attrs.isNew;
      }, function (newVal) {
        if (scope.$eval(attrs.isNew)) {
          element[0].value = true;
          scope.handleExpand({element: element.find(".accordion__label")[0], index: scope.$eval(attrs.index)});
        }
      });
    }
  };
});

app.directive("notEmpty", function () {
    return {
        restrict: "A",
        scope: {
          handleTriggerValidate: "&"
        },
        link: function (scope, element, attrs) {
            var regex = /^(.+)$/;
            element.on("keyup change", function (event) {
                if (!regex.test(event.target.value)) {
                    element.addClass("error");
                } else {
                    element.removeClass("error");
                }
            });
        }
    };
});

app.directive("setTabAutoActive", function ($timeout) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      if (scope.$eval(attrs.isNew)) {
        $timeout(function () {
          scope.$eval(attrs.setTabAutoActive);
        }, 50);
      }
    }
  };
});

app.directive("autofill", function () {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      scope.$on("autofill:update", function () {
        ngModel.$setViewValue(element.val());
      });
    }
  };
});

/* TODO: Deprecated */
app.directive("autofill", [
  "$timeout",
  function ($timeout) {
    return {
      scope: true,
      require: "ngModel",
      link: function (scope, elem, attrs, ctrl) {
        $timeout(function () {
          $(elem[0]).trigger("input");
          // elem.trigger('input'); try this if above don't work
        }, 200);
      }
    };
  }
]);
/* TODO: Deprecated */
app.directive("autoFillSync", [
  "$timeout",
  function ($timeout) {
    return {
      require: "ngModel",
      link: function (scope, elem, attrs, ngModel) {
        var origVal = elem.val();
        $timeout(function () {
          var newVal = elem.val();
          if (ngModel.$pristine && origVal !== newVal) {
            ngModel.$setViewValue(newVal);
          }
        }, 500);
      }
    };
  }
]);

app.directive("repeatFinished", function () {
  return function (scope, element, attrs) {
    if (scope.$last) {
      scope.$emit("NG_REPEAT_FINISHED");
    }
  };
});
/* TODO: Deprecated */
app.directive("formAutofillFix", function () {
  return function (scope, elem, attrs) {
    // Fixes Chrome bug: https://groups.google.com/forum/#!topic/angular/6NlucSskQjY
    elem.prop("method", "POST");

    // Fix autofill issues where Angular doesn't know about autofilled inputs
    if (attrs.ngSubmit) {
      setTimeout(function () {
        elem.unbind("submit").submit(function (e) {
          e.preventDefault();
          elem.find("input, textarea, select").trigger("input").trigger("change").trigger("keydown");
          scope.$apply(attrs.ngSubmit);
        });
      }, 0);
    }
  };
});

/* TODO: Deprecated */
//hack to trigger invoice supplier typeahead on button click
app.directive("typeaheadOpenTrigger", function () {
  return {
    require: ["ngModel"],
    link: function (scope, element, attr, ctrls) {
      var openClickHandler = function (evt) {
        scope.fromKi = true;
        var vv = ctrls[0].$viewValue;
        ctrls[0].$setViewValue(" ");
        scope.$digest();
        ctrls[0].$setViewValue(vv);
        scope.$digest();
      };

      $(element).parent().find(".typeahead-trigger").bind("click", openClickHandler);
    }
  };
});

app.directive("parseNumberOnBlur", [
  "$filter",
  "$timeout",
  function ($filter, $timeout) {
    return {
      require: "?ngModel",
      link: function (scope, element, attr, ngModel) {
        $timeout(function () {
          ngModel.$setViewValue($filter("number")(ngModel.$modelValue, 2));
        });
        element.bind("blur", function () {
          ngModel.$setViewValue($filter("number")(ngModel.$modelValue, 2));
        });
      }
    };
  }
]);

app.directive("focusOn", function () {
  return function (scope, elem, attr) {
    scope.$on("focusOn", function (e, name) {
      if (name === attr.focusOn) {
        elem[0].focus();
      }
    });
  };
});

app.directive("typeaheadWatchChanges", function () {
  return {
    require: ["ngModel"],
    link: function (scope, element, attr, ctrls) {
      scope.$watch("thing", function (value) {
        ctrls[0].$setViewValue(value);
      });
    }
  };
});

app.directive("fileModel", function () {
  return {
    scope: true,        //create a new scope
    link: function (scope, el, attrs) {
      el.bind("change", function (event) {
        var files = event.target.files;
        //iterate files since 'multiple' may be specified on the element
        for (var i = 0; i < files.length; i++) {
          //emit event upward
          scope.$emit("fileSelected", {file: files[i]});
        }
      });
    }
  };
});

app.directive("ngZabutocalendar", function ($parse, $compile) {
  return {
    link: function ($scope, element, attributes) {
      //BEGIN CALENDAR
      $("#zabuto-calendar").zabuto_calendar({
        language: "en"
      });
      //END CALENDAR
    }
  };
});

app.directive("ngRepeatSlimscrollDirective", function () {
  return function (scope, element, attrs) {
    if (scope.$last) {
      $("#" + element[0].parentElement.id).slimScroll({
        "width": "100%",
        "height": element[0].parentElement.clientHeight,
        "wheelStep": 30,
        "scrollTo": "100px",
        "alwaysVisible": true
      });
    }
  };
});

app.directive("ngAreachartspline", function () {
  return {
    scope: {
      paid: "=paid",
      issued: "=issued"
    },
    link: function ($scope, element, attributes) {
      //BEGIN AREA CHART SPLINE
      $.plot("#area-chart-spline", [
        {
          data: $scope.issued,
          label: "Esitatud",
          color: "#ffce54"
        },
        {
          data: $scope.paid,
          label: "Tasustatud",
          color: "#01b6ad"
        }
      ], {
        series: {
          lines: {
            show: !1
          },
          splines: {
            show: !0,
            tension: 0.4,
            lineWidth: 2,
            fill: 0.8
          },
          points: {
            show: !0,
            radius: 4
          }
        },
        grid: {
          borderColor: "#fafafa",
          borderWidth: 1,
          hoverable: !0
        },
        tooltip: !0,
        tooltipOpts: {
          content: "%x : %y",
          defaultTheme: true
        },
        xaxis: {
          tickColor: "#fafafa",
          mode: "categories"
        },
        yaxis: {
          tickColor: "#fafafa"
        },
        shadowSize: 0
      });
      //END AREA CHART SPLINE
    }
  };
});

app.directive("noSpaces", function () {
  return {
    restrict: "A",
    require: "?ngModel",
    link: function (scope, element, attrs, ngModel) {
      if (!ngModel) return;
      ngModel.$parsers.unshift(function (inputValue) {
        inputValue = inputValue.replace(" ", "");
        ngModel.$setViewValue(inputValue);
        ngModel.$render();
        return inputValue;
      });

      element.bind("keypress", function (event) {
        if (event.keyCode === 32) {
          event.preventDefault();
        }
      });
    }
  };
});

app.directive("numbersOnly", function () {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      var regex = /[^0-9,.]/g;
      element.on("keyup change", function (event) {
        element.target.value.replace(regex, '');
      });
    }
  };
});

app.directive("findCustomFieldValue", function () {
  return {
    restrict: "A",
    scope: {
      item: "=",
      descriptor: "=",
      param: "@"
    },
    link: function (scope, element, attrs) {
      if (scope.descriptor && scope.descriptor.hasOwnProperty("Dimension")) {
      }
    }
  };
});

app.directive("focusLastAdded", ["$timeout", function ($timeout) {
    return {
        restrict: "A",
        link: function (scope, element, attrs) {
            scope.$watch(function () {
                return element.find(".tab").length;
            }, function () {
                var list = element.find(".tab");
                var newItems = [];
                angular.forEach(list, function (item) {
                    var elemScope = angular.element(item).scope();
                    if (elemScope.allocation && elemScope.allocation.isNew) {
                      newItems.push(item);
                    }
                });
                if (newItems.length) {
                    $timeout(function () {
                        angular.element(newItems[0]).find(".allocation-description")[0].focus();
                    }, 25);
                }
            });
        }
    };
}]);

app.directive("addXUse", function () {
    return {
        restrict: "A",
        scope: {
            currentVersion: "=currentVersion",
            iconName: "=addXUse"
        },
        link: function (scope, element, attrs) {
            var svgRootPath = "images/svg-defs.svg?";
            var iconPath = svgRootPath + scope.currentVersion + "#icon-" + scope.iconName;
            element.attr("xlink:href", iconPath);
        }
    };
});

app.directive("clearFilterObject", function (invoiceRegisterService) {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      const prefix = attrs.clearFilterObject;
      function clearFilterFromLocalStorage() {
        invoiceRegisterService.removeFilterFromLocalStorage(prefix);
      }

      element.on('click', clearFilterFromLocalStorage)
    }
  }
});

app.directive("formInitialized", () => {
    return (scope, elem, attrs) => {
        if ([constants.FormInitEvent.vatCode, constants.FormInitEvent.transactionRows].includes(attrs.formInitialized)) {
            scope.$emit('formInitialized', attrs.formInitialized);
        }
        if (attrs.formInitialized === constants.FormInitEvent.customFields && scope.$last) {
            scope.$emit('formInitialized', attrs.formInitialized);
        }
    }
});
