import { selectCurrentCompanySettings } from '../company/CompanySelectors';
import { useSelector } from 'react-redux';
import { SystemConfigurationDTO } from '../../services/types/ApiTypes';
import { useCallback } from 'react';
import { CompanySetting } from '../user/userCompanySettingsUtil';

export type CompanySettingName = keyof typeof CompanySetting;

export type SettingConfig = Omit<SystemConfigurationDTO, 'Name'> & { Name: CompanySettingName };

const getByName = (settingsList: SystemConfigurationDTO[], name: CompanySettingName) => {
    return (
        (settingsList.find((setting) => {
            return setting.Name === name;
        }) as SettingConfig) || null
    );
};

export const useCompanySetting = (name: CompanySettingName) => {
    const settingsList = useSelector(selectCurrentCompanySettings);

    const getSettingByName = useCallback(
        (name: CompanySettingName) => {
            return getByName(settingsList, name);
        },
        [settingsList],
    );
    return getSettingByName(name);
};

export const useCompanySettings = () => {
    const settingsList = useSelector(selectCurrentCompanySettings);

    const getSettingsByName = useCallback(() => {
        const settings: Partial<Record<CompanySettingName, string | null>> = {};
        for (const setting in CompanySetting) {
            if (setting) {
                const settingName = setting as CompanySettingName;
                const settingValue = getByName(settingsList, settingName);
                if (settingValue) {
                    settings[settingName] = settingValue.Value || null;
                }
            }
        }
        return settings as Record<CompanySettingName, string | null>;
    }, [settingsList]);

    return getSettingsByName();
};
