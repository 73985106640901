import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { AccountDTO, AccountsUploadCSVResponse, BaseSearch, FileDTO, PagedListContainer, Restriction } from '../types/ApiTypes';
import { UploadProgress } from '../ApiClient';

export class AccountService {
    constructor(private axios: AxiosInstance) {}

    getAccounts = async (searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<AccountDTO>>> => {
        return this.axios.post('/Account/GetAccounts', searchParams);
    };

    addAccount = async (account: AccountDTO): Promise<AxiosResponse<AccountDTO>> => {
        return this.axios.post('/Account/AddAccount', account);
    };

    editAccount = async (account: AccountDTO): Promise<AxiosResponse<AccountDTO>> => {
        return this.axios.post('/Account/EditAccount', account);
    };

    deleteAccount = async (id: number): Promise<AxiosResponse<any>> => {
        return this.axios.delete(`/Account/DeleteAccount/${id}`);
    };

    uploadCSVFile = async (file: FileDTO, onUploadProgress?: UploadProgress): Promise<AxiosResponse<AccountsUploadCSVResponse>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post('/Account/UploadAccountFile', file, config);
    };

    exportAccountsToCSV = (restrictions: Restriction[]) => {
        return this.axios.post('/Account/ExportAccountsToCSV', restrictions, { responseType: 'blob' });
    };

    exportAccountsToXLSX = (restrictions: Restriction[]) => {
        return this.axios.post('/Account/ExportAccountsToXls', restrictions, { responseType: 'blob' });
    };
}

export default AccountService;
