import { push, replace } from 'connected-react-router';
import { createAction } from 'redux-actions';

import { paths, resolvePathParams } from '../../../common/router/routePaths';
import { getViewUserSearchParams, ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { FileReaderResult } from '../../../common/utils/fileReader';
import { notify } from '../../../common/utils/notify';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { saveFileFromResponse } from '../../../common/utils/saveFileFromResponse';
import i18nInstance from '../../../i18n';
import { GlobalState } from '../../../rootReducer';
import { BaseSearch, CustomCostObjectiveFullDTO, DimensionDTO, FileDTO, PagedListContainer, Restriction, SortDirection, UserSettingName } from '../../../services/types/ApiTypes';
import api from '../../../services/ApiServices';
import { DispatchThunk } from '../../../storeConfig';
import { DEFAULT_RESTRICTION } from './CostObjectiveDetailsViewReducer';
import { isEmpty } from 'lodash-es';
import { getCurrentUserGroupMember, updateCustomCostObjectiveAction } from '../../../common/user/UserActions';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import { updateAppUserSettingsAction } from '../../../common/middlewares/userSettings';

const ns = 'settings/cost-objective-details/';
const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.SETTINGS_ACCOUNTING_COST_OBJECTIVES_SORT_DIRECTION,
    sortCol: UserSettingName.SETTINGS_ACCOUNTING_COST_OBJECTIVES_SORT_COLUMN,
    pageSize: UserSettingName.SETTINGS_ACCOUNTING_COST_OBJECTIVES_PAGE_SIZE,
};

export enum SearchColumnName {
    CODE = 'Code',
    DESCRIPTION = 'Description',
    START_DATE = 'StartDate',
    END_DATE = 'EndDate',
    IN_USE_COUNT = 'InUseCount',
}

export const loadCostObjectiveLoadableActions = loadableDataActions<number, CustomCostObjectiveFullDTO>(`${ns}LOAD_COST_OBJECTIVE`);
export const saveCostObjectiveLoadableActions = loadableDataActions<boolean, boolean>(`${ns}SAVE_COST_OBJECTIVE`);

export function loadCostObjective(id: number) {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(loadCostObjectiveLoadableActions.request(id));
            response = await api.customCostObjective.getCustomCostObjective(id);
            dispatch(loadCostObjectiveLoadableActions.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(loadCostObjectiveLoadableActions.error(e));
        }
    };
}

export const setPagingOptions = (page?: number, pageSize?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState): Promise<any> => {
        const { costObjectiveDetails } = getState();
        const paging = costObjectiveDetails.costObjectiveItemsSearchParams.PagingOptions;

        const searchParams: BaseSearch = {
            ...costObjectiveDetails.costObjectiveItemsSearchParams,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        return dispatch(searchCostObjectiveItems(searchParams));
    };
};

export const loadCostObjectiveItemsLoadableActions = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<DimensionDTO>>(`${ns}LOAD_COST_OBJECTIVE_ITEMS`);

export function searchCostObjectiveItems(searchParams?: BaseSearch | undefined, id?: number) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const groupMemberCommonLoadable = getState().user.groupMemberCommonLoadable;
        if (isEmpty(groupMemberCommonLoadable.payload) && !groupMemberCommonLoadable.loading) {
            await dispatch(getCurrentUserGroupMember());
        }
        const {
            costObjectiveDetails,
            user: {
                groupMemberCommonLoadable: { payload: groupMember },
            },
        } = getState();
        searchParams = searchParams || costObjectiveDetails.costObjectiveItemsSearchParams;
        if (searchParams.Restrictions[0].Field === 'Code') {
            searchParams.Restrictions[0].Field = DEFAULT_RESTRICTION;
        }

        const viewSearchParams = getViewUserSearchParams(searchParams, listViewConfig, groupMember);
        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(
            viewSearchParams.SortItems,
            'Code',
            SortDirection.Asc,
            Object.keys(SearchColumnName).map((x) => SearchColumnName[x]),
        );

        searchParams = { ...searchParams, ...viewSearchParams };

        id = id || costObjectiveDetails.costObjectiveId;
        let response;
        try {
            dispatch(loadCostObjectiveItemsLoadableActions.request(searchParams));
            response = await api.customCostObjective.getCustomCostObjectiveDimensions(id, searchParams);
            dispatch(
                loadCostObjectiveItemsLoadableActions.success({
                    request: searchParams,
                    result: response.data,
                }),
            );
            // if our page for some reason is empty, but there is data on previous pages, then load previous pages until we have some data to display
            if (response.data.Items.length === 0 && response.data.HasCount) {
                const currentPage = costObjectiveDetails.costObjectiveItemsSearchParams.PagingOptions.Page;
                if (currentPage > 1) {
                    return dispatch(setPagingOptions(currentPage - 1));
                }
            }
            return Promise.resolve();
        } catch (e) {
            console.error(e);
            dispatch(
                loadCostObjectiveItemsLoadableActions.error({
                    request: searchParams,
                    result: e,
                }),
            );
            return Promise.resolve();
        }
    };
}

export const filterCostObjectiveItems = (searchString: string, id?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const paging = state.costObjectiveDetails.costObjectiveItemsSearchParams.PagingOptions;
        const searchRestriction = state.costObjectiveDetails.costObjectiveItemsSearchParams.Restrictions[0];
        const searchParams: BaseSearch = {
            ...state.costObjectiveDetails.costObjectiveItemsSearchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: [
                {
                    ...searchRestriction,
                    Value: searchString,
                },
            ],
        };
        dispatch(searchCostObjectiveItems(searchParams, id));
    };
};

export const sortCostObjectiveItems = (columnName: string) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { costObjectiveDetails } = getState();
        const sorting = costObjectiveDetails.costObjectiveItemsSearchParams.SortItems[0];

        const searchParams: BaseSearch = {
            ...costObjectiveDetails.costObjectiveItemsSearchParams,
            SortItems: [
                {
                    SortColumn: columnName,
                    SortDirection: sorting.SortColumn === columnName ? (sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                },
            ],
        };

        await dispatch(
            updateAppUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );

        dispatch(searchCostObjectiveItems(searchParams));
    };
};

export const addNewRow = createAction(`${ns}ADD_NEW_ROW`);

export const gotoLastPageAndAddNewRow = () => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const payload = state.costObjectiveDetails.costObjectiveItemsLoadable.payload;
        const lastPage = Math.ceil(payload.TotalCount / payload.Take) || 1;
        const currentPage = payload.Skip / payload.Take + 1;
        if (lastPage !== currentPage) {
            await dispatch(setPagingOptions(lastPage));
        }
        dispatch(addNewRow());
    };
};

export const updateRow = createAction<{ result: DimensionDTO; itemToUpdate: number }>(`${ns}UPDATE_ROW`);

export const saveRow = (item: DimensionDTO) => {
    return async (dispatch: DispatchThunk) => {
        try {
            let result;
            if (item.Id > 0) {
                result = await api.customCostObjective.updateDimension(item);
            } else {
                result = await api.customCostObjective.addDimension(item);
            }
            dispatch(
                updateRow({
                    result: result.data,
                    itemToUpdate: result.data.Id > 0 ? result.data.Id : item.Id,
                }),
            );
        } catch (e) {
            console.error(e);
            if (e?.response?.data?.ErrorCode) {
                notify.error(i18nInstance.t(e.response.data.ErrorCode).replace('{0}', item.Code));
            } else {
                notify.info(i18nInstance.t('view.Accounting.RowSavingFailed'));
            }
        }
    };
};

export const save = (template: CustomCostObjectiveFullDTO, changeReason?: string) => {
    return async (dispatch: DispatchThunk) => {
        try {
            let result;
            if (template.Id > 0) {
                dispatch(saveCostObjectiveLoadableActions.request(undefined));
                result = await api.customCostObjective.editCustomCostObjective(template);
                dispatch(updateCustomCostObjectiveAction(result.data));
            } else {
                result = await api.customCostObjective.addCustomCostObjective(template);
                dispatch(loadCostObjectiveLoadableActions.success(result.data));
                dispatch(searchCostObjectiveItems(undefined, result.data.Id));
                dispatch(push(resolvePathParams(paths.app.costObjectiveDetails, { id: result.data.Id })));
            }
            dispatch(saveCostObjectiveLoadableActions.success(true));
            if (changeReason) {
                notify.success(changeReason);
            }
        } catch (e) {
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'), i18nInstance.t('interceptorsFactory.Error'));
            dispatch(saveCostObjectiveLoadableActions.error(Error(i18nInstance.t('interceptorsFactory.ErrorWhileSendingData'))));
            console.error(e);
        }
    };
};

export const deleteCostObjective = () => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const id = getState().costObjectiveDetails.costObjectiveId;
            const name = getState().costObjectiveDetails.costObjectiveLoadable.payload.Description;
            await api.customCostObjective.deleteCustomCostObjective(id);
            notify.success(i18nInstance.t('view.Accounting.CostObjective.Deleted', { name }));
            dispatch(push(paths.app.accounting));
        } catch (e) {
            console.error(e);
        }
    };
};

export const removeRow = createAction<number>(`${ns}REMOVE_ROW`);

export const deleteRow = (id: number) => {
    return async (dispatch: DispatchThunk) => {
        try {
            if (id > 0) {
                await api.customCostObjective.deleteDimension(id);
            }
            dispatch(removeRow(id));
            dispatch(searchCostObjectiveItems()); // lets reload to keep the item count on page up-to-date
        } catch (e) {
            console.error(e);
        }
    };
};

export function replaceUrlToDetails() {
    return (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const costObjectiveId = getState().costObjectiveDetails.costObjectiveId;
            dispatch(replace(resolvePathParams(paths.app.costObjectiveDetails, { id: costObjectiveId })));
        } catch (e) {
            console.error(e);
        }
    };
}

export function uploadCSV(fileReaderResult: FileReaderResult, parentCode: string) {
    return async (dispatch: DispatchThunk) => {
        try {
            const file: FileDTO = {
                Base64Content: fileReaderResult.base64Content,
                FileName: fileReaderResult.fileName,
                WorkflowDocumentId: undefined,
                DownloadUrl: undefined,
                FileUrl: undefined,
                ForceImport: undefined,
                HubType: undefined,
                Id: undefined,
                IsImported: undefined,
                IsNew: undefined,
                IsPdf: undefined,
                PathToFile: undefined,
            };

            const result = await api.customCostObjective.uploadDimensionFile(parentCode, file);

            if (result && result.data && result.data.hasOwnProperty('ValidationErrors') && result.data.ValidationErrors.length > 0) {
                notify.error(i18nInstance.t('views.global.ErrorUploadingFile'));
            }

            dispatch(setPagingOptions(1));
        } catch (e) {
            notify.error(i18nInstance.t('views.global.ErrorUploadingFile'), i18nInstance.t('interceptorsFactory.Error'));
            console.error(e);
        }
    };
}

export const exportCostObjectivesToCSVLoadableActions = loadableDataActions(`${ns}EXPORT_COST_OBJECTIVES_TO_CSV`);
export const exportCostObjectivesToCSV = (id: number, restrictions: Restriction[]) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(exportCostObjectivesToCSVLoadableActions.request(null));
            const result = await api.customCostObjective.exportDimensionsToCSV(id, restrictions);
            saveFileFromResponse(result, { fileExtension: 'csv', namePrefix: 'Dimensions' });
            dispatch(exportCostObjectivesToCSVLoadableActions.success(null));
        } catch (e) {
            console.error(e);
            dispatch(exportCostObjectivesToCSVLoadableActions.error(e));
        }
    };
};
export const exportCostObjectivesToXLSXLoadableActions = loadableDataActions(`${ns}EXPORT_COST_OBJECTIVES_TO_XLSX`);
export const exportCostObjectivesToXLSX = (id: number, restrictions: Restriction[]) => {
    return async (dispatch: DispatchThunk) => {
        try {
            dispatch(exportCostObjectivesToXLSXLoadableActions.request(null));
            const result = await api.customCostObjective.exportDimensionsToXls(id, restrictions);
            saveFileFromResponse(result, { fileExtension: 'xlsx', namePrefix: 'Dimensions' });
            dispatch(exportCostObjectivesToXLSXLoadableActions.success(null));
        } catch (e) {
            console.error(e);
            dispatch(exportCostObjectivesToXLSXLoadableActions.error(e));
        }
    };
};
