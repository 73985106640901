import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { History } from 'history';

import company, { CompanyState } from './common/company/CompanyReducer';
import companySettings, { CompanySettingsViewState } from './views/settings/company-settings/CompanySettingsViewReducer';
import companySettingsList, { CompanySettingsListState } from './views/settings/company-settings/components/Settings/CompanySettingsListReducer';
import invoiceFilterHistory, { InvoiceFilterHistoryState } from './common/history/InvoiceFilterReducer';
import user, { UserState } from './common/user/UserReducer';
import changeCompany, { ChangeCompanyState } from './components/ChangeCompany/ChangeCompanyReducer';
import userProfile, { UserProfileState } from './components/UserProfile/UserProfileReducer';
import editSupplierModal, { EditSupplierModalState } from './components/EditSupplierModal/EditSupplierModalReducer';
import mainMenu, { MainMenuState } from './components/MainMenu/MainMenuReducer';
import autoTransactionAdd, { AutoTransactionAddViewState } from './views/auto-transactions-add/AutoTransactionsAddViewReducer';
import autoTransactions, { AutoTransactionsViewState } from './views/auto-transactions/AutoTransactionsViewReducer';
import dashboardPurchaseOrders, { DashboardPurchaseOrdersState } from './views/dashboard-purchase-orders/DashboardPurchaseOrdersReducers';
import invoiceData, { InvoiceDataState } from './views/invoice-details/components/invoice-export-management/InvoiceExportManagementReducers';
import purchaseOrdersAdd, { PurchaseOrdersAddViewState } from './views/purchase-orders-add/PurchaseOrdersAddViewReducer';
import purchaseOrders, { PurchaseOrdersViewState } from './views/purchase-orders/PurchaseOrdersViewReducer';
import purchaseOrderHistory, { PurchaseOrderHistoryState } from './views/purchase-orders-add/components/HistoryCard/PurchaseOrderHistoryReducers';
import purchaseOrderInvoices, { PurchaseOrderInvoicesState } from './views/purchase-orders-add/components/Header/InvoicesList/InvoicesListReducers';
import accountDetails, { AccountDetailsViewState } from './views/settings/account-details/AccountDetailsViewReducer';
import accounting, { AccountingViewState } from './views/settings/accounting/AccountingViewReducer';
import productItems, { ProductItemsViewState } from './views/settings/product-items/ProductItemsViewReducer';
import editProductItemModal, { EditProductItemModalState } from './views/settings/product-items/EditProductItemModal/EditProductItemModalReducer';
import costObjectiveDetails, { CostObjectiveDetailsViewState } from './views/settings/cost-objective-details/CostObjectiveDetailsViewReducer';
import relatedDimensions, { RelatedDimensionsViewState } from './views/automation/related-dimensions/RelatedDimensionsViewReducer';
import suppliers, { SuppliersViewState } from './views/settings/suppliers/SuppliersViewReducer';
import vatCodeDetails, { VatCodeDetailsViewState } from './views/settings/vat-code-details/VatCodeDetailsViewReducer';
import userAdd, { UserAddViewState } from './views/user-add/UserAddViewReducer';
import userManagement, { UserManagementViewState } from './views/user-list/UserListViewReducer';
import invoiceTypes, { InvoiceTypesViewState } from './views/settings/invoice-types/InvoiceTypesListReducer';
import paymentMethods, { PaymentMethodsViewState } from './views/settings/payment-methods/PaymentMethodsReducer';
import headerExtensions, { HeaderExtensionsViewState } from './views/settings/header-extensions/HeaderExtensionsReducer';
import editHeaderExtensionModal, { EditHeaderExtensionModalState } from './views/settings/header-extensions/EditHeaderExtensionModal/EditHeaderExtensionModalReducer';
import editInvoiceTypeModal, { EditInvoiceTypeModalState } from './views/settings/invoice-types/EditInvoiceTypeModal/EditInvoiceTypeModalReducer';
import editPaymentMethodModal, { EditPaymentMethodModalState } from './views/settings/payment-methods/EditPaymentMethodModal/EditPaymentMethodModalReducer';
import workflows, { WorkflowsListViewState } from './views/settings/workflows-list/WorkflowsListViewReducers';
import workflow, { WorkflowDetailsViewState } from './views/settings/workflow-details/WorkflowDetailsViewReducers';
import sliderInvoiceData, { SliderInvoiceDataState } from './views/invoice-details/components/invoice-slider/InvoiceSliderReducers';
import invoiceHistory, { InvoiceHistoryState } from './views/invoice-details/components/invoice-history/InvoiceHistoryReducers';
import changeLanguage, { ChangeLanguageState } from './components/ChangeLanguage/ChangeLanguageReducer';
import boUser, { BoUserState } from './views/back-office/common/BoUserReducer';
import boUserManagement, { BoUserManagementViewState } from './views/back-office/user-list/BOUserListViewReducer';
import boIntegratorsManagement, { BoIntegratorManagementViewState } from './views/back-office/integrators/BOIntegratorListViewReducer';
import boDomainsManagement, { BoDomainManagementViewState } from './views/back-office/domains/BODomainListViewReducer';
import editBoUserModal, { EditBoUserModalState } from './views/back-office/user-list/components/EditBoUserModal/EditBoUserModalReducer';
import boCompanyManagement, { BOCompanyManagementViewState } from './views/back-office/company-list/BOCompanyListViewReducer';
import editBoCompanyModal, { EditBoCompanyModalState } from './views/back-office/company-list/components/AddBoCompanyModal/AddBoCompanyModalReducer';
import boCompanyDetails, { BOCompanyDetailsViewState } from './views/back-office/company-details/BOCompanyDetailsViewReducer';
import boOrganizationManagement, { BOOrganizationManagementViewState } from './views/back-office/organization-list/BOOrganizationListViewReducer';
import editBoOrganizationModal, { EditBoOrganizationModalState } from './views/back-office/organization-list/components/EditBoOrganizationModal/EditBoOrganizationModalReducer';
import boResellerManagement, { BOResellerManagementViewState } from './views/back-office/reseller-list/BOResellerListViewReducer';
import boReporting, { BOReportingViewState } from './views/back-office/reporting/BOReportingListViewReducer';
import editBoResellerModal, { EditBoResellerModalState } from './views/back-office/reseller-list/components/EditBoResellerModal/EditBoResellerModalReducer';
import openExternalLink, { OpenExternalLinkState } from './components/OpenExternalLinkConfirm/OpenExternalLinkConfirmModalReducer';
import invoiceConfirmData, { InvoiceConfirmDataState } from './views/invoice-details/components/invoice-confirmations/InvoiceConfirmationsReducer';
import dashboardRejectedInvoices, { DashboardRejectedInvoicesState } from './views/dashboard/rejected-invoices/DashboardRejectedInvoicesReducer';
import invoicePurchaseOrders, { InvoicePurchaseOrdersState } from './views/invoice-details/components/invoice-header/components/PurchaseOrdersList/PurchaseOrdersListReducers';
import invoiceHeaderHistoryList, { InvoiceHeaderHistoryListState } from './views/invoice-details/components/invoice-header/components/HistoryList/HistoryListReducers';
import invoiceRows, { InvoiceRowsViewState } from './views/invoice-details/components/invoice-rows/InvoiceRowsReducers';
import transactionRows, { TransactionRowsViewState } from './views/invoice-details/components/transaction-rows/TransactionRowsReducers';
import invoiceTransactionRows, { InvoiceTransactionRowsViewState } from './views/invoice-details/components/invoice-transaction-rows/TransactionRowsReducers';
import fileViewData, { FileViewState } from './components/PDFViewer/FileViewReducer';
import menuLock, { MenuLockState } from './common/menu-lock/MenuLockReducer';

const createRootReducer = (history: History) => {
    return combineReducers<GlobalState>({
        router: connectRouter(history),
        user,
        userManagement,
        invoiceTypes,
        editInvoiceTypeModal,
        editPaymentMethodModal,
        userAdd,
        company,
        companySettings,
        companySettingsList,
        userProfile,
        headerExtensions,
        editHeaderExtensionModal,
        paymentMethods,
        purchaseOrdersAdd,
        purchaseOrders,
        purchaseOrderHistory,
        purchaseOrderInvoices,
        dashboardPurchaseOrders,
        dashboardRejectedInvoices,
        autoTransactionAdd,
        invoiceFilterHistory,
        autoTransactions,
        invoiceData,
        invoicePurchaseOrders,
        invoiceHeaderHistoryList,
        invoiceRows,
        transactionRows,
        invoiceTransactionRows,
        accounting,
        costObjectiveDetails,
        accountDetails,
        vatCodeDetails,
        workflows,
        workflow,
        changeCompany,
        mainMenu,
        productItems,
        editProductItemModal,
        relatedDimensions,
        sliderInvoiceData,
        invoiceHistory,
        suppliers,
        editSupplierModal,
        changeLanguage,
        boUser,
        boUserManagement,
        boIntegratorsManagement,
        boDomainsManagement,
        editBoUserModal,
        boCompanyManagement,
        boCompanyDetails,
        editBoCompanyModal,
        boOrganizationManagement,
        openExternalLink,
        editBoOrganizationModal,
        boResellerManagement,
        boReporting,
        editBoResellerModal,
        invoiceConfirmData,
        fileViewData,
        menuLock,
    });
};

export interface GlobalState {
    router?: RouterState;
    user: UserState;
    userManagement: UserManagementViewState;
    invoiceTypes: InvoiceTypesViewState;
    editInvoiceTypeModal: EditInvoiceTypeModalState;
    userAdd: UserAddViewState;
    company: CompanyState;
    companySettings: CompanySettingsViewState;
    companySettingsList: CompanySettingsListState;
    autoTransactionAdd: AutoTransactionAddViewState;
    paymentMethods: PaymentMethodsViewState;
    headerExtensions: HeaderExtensionsViewState;
    editHeaderExtensionModal: EditHeaderExtensionModalState;
    purchaseOrdersAdd: PurchaseOrdersAddViewState;
    purchaseOrders: PurchaseOrdersViewState;
    purchaseOrderHistory: PurchaseOrderHistoryState;
    purchaseOrderInvoices: PurchaseOrderInvoicesState;
    dashboardPurchaseOrders: DashboardPurchaseOrdersState;
    dashboardRejectedInvoices: DashboardRejectedInvoicesState;
    userProfile: UserProfileState;
    invoiceFilterHistory: InvoiceFilterHistoryState;
    autoTransactions: AutoTransactionsViewState;
    invoiceData: InvoiceDataState;
    invoiceTransactionRows: InvoiceTransactionRowsViewState;
    invoicePurchaseOrders: InvoicePurchaseOrdersState;
    invoiceHeaderHistoryList: InvoiceHeaderHistoryListState;
    invoiceRows: InvoiceRowsViewState;
    transactionRows: TransactionRowsViewState;
    accounting: AccountingViewState;
    costObjectiveDetails: CostObjectiveDetailsViewState;
    accountDetails: AccountDetailsViewState;
    vatCodeDetails: VatCodeDetailsViewState;
    workflows: WorkflowsListViewState;
    workflow: WorkflowDetailsViewState;
    changeCompany: ChangeCompanyState;
    mainMenu: MainMenuState;
    productItems: ProductItemsViewState;
    editPaymentMethodModal: EditPaymentMethodModalState;
    editProductItemModal: EditProductItemModalState;
    relatedDimensions: RelatedDimensionsViewState;
    sliderInvoiceData: SliderInvoiceDataState;
    invoiceHistory: InvoiceHistoryState;
    suppliers: SuppliersViewState;
    editSupplierModal: EditSupplierModalState;
    changeLanguage: ChangeLanguageState;
    boUser: BoUserState;
    boUserManagement: BoUserManagementViewState;
    boIntegratorsManagement: BoIntegratorManagementViewState;
    boDomainsManagement: BoDomainManagementViewState;
    editBoUserModal: EditBoUserModalState;
    boCompanyManagement: BOCompanyManagementViewState;
    boCompanyDetails: BOCompanyDetailsViewState;
    editBoCompanyModal: EditBoCompanyModalState;
    boOrganizationManagement: BOOrganizationManagementViewState;
    editBoOrganizationModal: EditBoOrganizationModalState;
    openExternalLink: OpenExternalLinkState;
    boResellerManagement: BOResellerManagementViewState;
    boReporting: BOReportingViewState;
    editBoResellerModal: EditBoResellerModalState;
    invoiceConfirmData: InvoiceConfirmDataState;
    fileViewData: FileViewState;
    menuLock: MenuLockState;
}

export default createRootReducer;
