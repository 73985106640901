import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiResultOfListOfCustomCostObjectiveDTO, BaseSearch, CustomCostObjectiveFullDTO, DimensionDTO, FileDTO, PagedListContainer, Restriction } from '../types/ApiTypes';
import { UploadProgress } from '../ApiClient';

export class CustomCostObjectiveService {
    constructor(private axios: AxiosInstance) {}

    getByDescriptionPart = async (description: string, includeDimensions: boolean): Promise<AxiosResponse<CustomCostObjectiveFullDTO[]>> => {
        return this.axios.get('/CustomCostObjective/GetByDescriptionPart', {
            params: {
                descriptionPart: description,
                includeDimensions,
            },
        });
    };
    addCustomCostObjective = async (item: CustomCostObjectiveFullDTO): Promise<AxiosResponse<CustomCostObjectiveFullDTO>> => {
        return this.axios.post(`/CustomCostObjective/AddCustomCostObjective`, {
            ...item,
            Id: undefined,
        });
    };
    editCustomCostObjective = async (item: CustomCostObjectiveFullDTO): Promise<AxiosResponse<CustomCostObjectiveFullDTO>> => {
        return this.axios.post(`/CustomCostObjective/EditCustomCostObjective`, {
            ...item,
            Dimensions: undefined,
        });
    };
    deleteCustomCostObjective = async (id: number): Promise<AxiosResponse<any>> => {
        return this.axios.delete(`/CustomCostObjective/DeleteCustomCostObjective/${id}`);
    };
    getCustomCostObjective = async (id: number, includeDimensions = false): Promise<AxiosResponse<CustomCostObjectiveFullDTO>> => {
        return this.axios.get(`/CustomCostObjective/GetCustomCostObjective/${id}?includeDimensions=${includeDimensions}`);
    };
    getCompanyCustomCostObjectivesWithoutDimensions = async (): Promise<AxiosResponse<ApiResultOfListOfCustomCostObjectiveDTO>> => {
        return this.axios.get('/CustomCostObjective/GetCompanyCustomCostObjectivesWithoutDimensions');
    };
    getCustomCostObjectiveDimensions = async (id: number, searchParams: BaseSearch): Promise<AxiosResponse<PagedListContainer<DimensionDTO>>> => {
        return this.axios.post('/CustomCostObjective/GetCustomCostObjectiveDimensions/' + id, searchParams);
    };
    updateCustomCostObjectiveOrderNumber = async (dimensionId: number, newOrderNumber: number): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/CustomCostObjective/UpdateCustomCostObjectiveOrderNumber?dimensionId=${dimensionId}&newOrderNumber=${newOrderNumber}`);
    };
    addDimension = async (item: DimensionDTO): Promise<AxiosResponse<DimensionDTO>> => {
        return this.axios.post(`/CustomCostObjective/AddDimension`, item);
    };
    updateDimension = async (item: DimensionDTO): Promise<AxiosResponse<DimensionDTO>> => {
        return this.axios.post(`/CustomCostObjective/UpdateDimension`, item);
    };
    deleteDimension = async (id: number): Promise<AxiosResponse<any>> => {
        return this.axios.delete(`/CustomCostObjective/DeleteDimension/${id}`);
    };
    uploadCSVFile = async (file: FileDTO, onUploadProgress?: UploadProgress): Promise<AxiosResponse<any>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post('/CustomCostObjective/UploadCSVFile', file, config);
    };
    uploadDimensionFile = async (parentCode: string, file: FileDTO, onUploadProgress?: UploadProgress): Promise<AxiosResponse<any>> => {
        const FILE_UPLOAD_TIMEOUT = 5 * 60 * 1000; // 5 minutes
        const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (onUploadProgress) {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(progress);
                }
            },
            timeout: FILE_UPLOAD_TIMEOUT,
        };
        return this.axios.post(`/CustomCostObjective/UploadDimensionFile?ccoCode=${parentCode}`, file, config);
    };

    exportAccountsToCSV = async (): Promise<AxiosResponse<any>> => {
        return this.axios.post('/CustomCostObjective/ExportAccountingToCSV', null, { responseType: 'blob' });
    };

    exportAccountsToXLSX = async (): Promise<AxiosResponse<any>> => {
        return this.axios.post('/CustomCostObjective/ExportAccountingToXls', null, { responseType: 'blob' });
    };

    exportDimensionsToCSV = async (customCostObjectiveId: number, restrictions: Restriction[]): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/CustomCostObjective/ExportDimensionsToCSV?id=${customCostObjectiveId}`, restrictions, { responseType: 'blob' });
    };

    exportDimensionsToXls = async (customCostObjectiveId: number, restrictions: Restriction[]): Promise<AxiosResponse<any>> => {
        return this.axios.post(`/CustomCostObjective/ExportDimensionsToXls?id=${customCostObjectiveId}`, restrictions, { responseType: 'blob' });
    };
}

export default CustomCostObjectiveService;
