import { useCallback } from 'react';
import { numericFormatter } from 'react-number-format';
import { NumericFormatProps } from 'react-number-format/types/types';

import { useCompanySettings } from '../../../common/hooks/useCompanySettings';
import { numberToNumericString, NumberToNumericStringOptions } from '../../../common/utils/numericUtils';

export type NumericFormatPropsWithNativeRound = NumericFormatProps &
    Pick<NumberToNumericStringOptions, 'ignoreBigIntConversion'> & {
        useNativeRound?: boolean;
    };

export type MonetaryScalesFormatFn = (value: number | string, props?: NumericFormatPropsWithNativeRound) => string;

export type UseMonetaryScalesFormat = {
    netVatTotal: MonetaryScalesFormatFn;
    itemPrice: MonetaryScalesFormatFn;
    quantity: MonetaryScalesFormatFn;
};

export const formatPrecisionScales = (value: string | number, numericFormatProps?: NumericFormatPropsWithNativeRound) => {
    const { decimalScale, ignoreBigIntConversion = false, ...restProps } = numericFormatProps;
    const decimalCount = numericFormatProps?.decimalScale || Number(decimalScale);
    const numStr = numberToNumericString(value, { ignoreBigIntConversion });
    const normalizedValue = !numericFormatProps.useNativeRound ? numStr : Number(numStr).toFixed(decimalCount);
    return numericFormatter(normalizedValue, { decimalScale: decimalCount, ...restProps });
};

export const useMonetaryScalesFormat = (): UseMonetaryScalesFormat => {
    const { MonetaryNetVatTotalPrecision, MonetaryAmountsItemPricePrecision, MonetaryAmountsQuantityPrecision } = useCompanySettings();
    const netVatDecimalScale = MonetaryNetVatTotalPrecision || '2';
    const itemPricePrecisionScale = MonetaryAmountsItemPricePrecision || '2';
    const qtyPrecisionScale = MonetaryAmountsQuantityPrecision || '2';

    const createFormatFunction = (precisionScale: string | number) => {
        return (value: number | string, numericFormatProps: NumericFormatPropsWithNativeRound) => {
            const decimalScale = numericFormatProps?.decimalScale;
            const options: NumericFormatProps = {
                ...numericFormatProps,
                decimalScale: Number(decimalScale || precisionScale),
            };
            return formatPrecisionScales(value, options);
        };
    };

    const netVatTotal: UseMonetaryScalesFormat['netVatTotal'] = useCallback(createFormatFunction(netVatDecimalScale), [netVatDecimalScale]);
    const itemPrice: UseMonetaryScalesFormat['itemPrice'] = useCallback(createFormatFunction(itemPricePrecisionScale), [itemPricePrecisionScale]);
    const quantity: UseMonetaryScalesFormat['quantity'] = useCallback(createFormatFunction(qtyPrecisionScale), [qtyPrecisionScale]);

    return { netVatTotal, itemPrice, quantity };
};
