import storeAndPersistor from '../../storeConfig';

import { getCurrentUser } from './UserSelectors';

export enum CompanySetting {
    DocumentTypes = 'DocumentTypes',
    IsConfirmAllAllowed = 'IsConfirmAllAllowed',
    IsQuickConfirmAllowed = 'IsQuickConfirmAllowed',
    IsKIEstonianSearchDisabled = 'IsKIEstonianSearchDisabled',
    IsIWSImportEnabled = 'IsIWSImportEnabled',
    ShowInvoicePdfPreviewAutomatically = 'ShowInvoicePdfPreviewAutomatically',
    EstonianRegNoTransformationEnabled = 'EstonianRegNoTransformationEnabled',
    IsExportWithUnregisteredSupplierDenied = 'IsExportWithUnregisteredSupplierDenied',
    IsTransactionRowsCheckEnabled = 'IsTransactionRowsCheckEnabled',
    IsTransactionRowsPenultimateCheckEnabled = 'IsTransactionRowsPenultimateCheckEnabled',
    IsVatSumDeviationWarningEnabled = 'IsVatSumDeviationWarningEnabled',
    IsVatCodeManualEditingEnabled = 'IsVatCodeManualEditingEnabled',
    SkipTransactionRowsVerificationWhenPOSpecified = 'SkipTransactionRowsVerificationWhenPOSpecified',
    IsERPIdEnabled = 'IsERPIdEnabled',
    CompanyRoundingOption = 'CompanyRoundingOption',
    IncludePdfIntoExport = 'IncludePdfIntoExport',
    AutoAssignToContactPerson = 'AutoAssignToContactPerson',
    OverwriteWithRelatedDimension = 'OverwriteWithRelatedDimension',
    FirstTransactionRowName = 'FirstTransactionRowName',
    IncludeInvoiceRowsIntoExport = 'IncludeInvoiceRowsIntoExport',
    NewVendorNotificationLanguage = 'NewVendorNotificationLanguage',
    DailyReminderSendingDays = 'DailyReminderSendingDays',
    CombinedRowName = 'CombinedRowName',
    SupportText = 'SupportText',
    UserManual = 'UserManual',
    SupportEmail = 'SupportEmail',
    SupportPhone = 'SupportPhone',
    NewSupplierEmail = 'NewSupplierEmail',
    CustomLoginUrl = 'CustomLoginUrl',
    ValidateExportXml = 'ValidateExportXml',
    NotifyExternalApi = 'NotifyExternalApi',
    NotifyExternalApiPurchaseOrder = 'NotifyExternalApiPurchaseOrder',
    PushInvoiceAfterExportUrl = 'PushInvoiceAfterExportUrl',
    AllowDimensionApisToCreateCco = 'AllowDimensionApisToCreateCco',
    AllowLoginWithUsernameAndPassword = 'AllowLoginWithUsernameAndPassword',
    AllowLoginWithEmailAndPassword = 'AllowLoginWithEmailAndPassword',
    InvoicesExportMode = 'InvoicesExportMode',
    ConfirmationFlowUserIdentifier = 'ConfirmationFlowUserIdentifier',
    ExportCompanySupplierDataInsteadOfOriginal = 'ExportCompanySupplierDataInsteadOfOriginal',
    SerbiaMidLayerEndpoint = 'SerbiaMidLayerEndpoint',
    MonetaryAmountsItemPricePrecision = 'MonetaryAmountsItemPricePrecision',
    MonetaryAmountsQuantityPrecision = 'MonetaryAmountsQuantityPrecision',
    ExportCSVIncludeColumnsSeparatorInformation = 'ExportCSVIncludeColumnsSeparatorInformation',
    ExportCSVDelimiterSymbol = 'ExportCSVDelimiterSymbol',
    UseSupplierErpCodeAsKey = 'UseSupplierErpCodeAsKey',
    SkipKeywordForSupplierMatchingByBankAccount = 'SkipKeywordForSupplierMatchingByBankAccount',
    MonetaryNetVatTotalPrecision = 'MonetaryNetVatTotalPrecision',
}

const store = storeAndPersistor.store;

/**
 * ideally the userData should be provided to guarantee re-rendering of components when userData changes
 * current returned result may remain outdated I think (not able to produce this case at the moment)
 * @param setting
 * @param userData
 */
export function isSettingEnabled(setting: string | CompanySetting, userData = getCurrentUser(store.getState())) {
    switch (setting) {
        case CompanySetting.IsTransactionRowsCheckEnabled:
            return userData.CompanySettings.includes(setting);
        case CompanySetting.IsTransactionRowsPenultimateCheckEnabled:
            return userData.CompanySettings.includes(setting);
        case CompanySetting.IsVatSumDeviationWarningEnabled:
            return userData.CompanySettings.includes(setting);
        case CompanySetting.SkipTransactionRowsVerificationWhenPOSpecified:
            return userData.CompanySettings.includes(setting);
        case CompanySetting.IsConfirmAllAllowed:
        case CompanySetting.IsQuickConfirmAllowed:
        default:
            return false;
    }
}
